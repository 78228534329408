import React, { FC } from 'react';
import { SASelect } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

interface FilterDocsProps {
  setSpecificDocType: Function;
  documentsTablePageChange: Function;
}

const SelectHolder = styled.div`
  margin-right: 15px;
  width: 110px;
  #document-type-dropdown {
    background-color: white;
  }
  @media (max-width: ${customBase.global.breakpoints.small}px) {
    margin: 15px 5px 0px 5px;
    width: 100%;
  }
`;

const FilterDocs: FC<FilterDocsProps> = ({
  setSpecificDocType,
  documentsTablePageChange,
}: FilterDocsProps) => (
  <SelectHolder>
    <SASelect
      fullWidth
      id="document-type-dropdown"
      label="Document Type"
      options={[
        { label: 'All', value: 'All' },
        { label: 'Policy', value: 'Policy' },
        { label: 'Billing', value: 'Billing' },
        { label: 'Claims', value: 'Claims' },
      ]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setSpecificDocType(e.currentTarget.value);
        documentsTablePageChange(0);
      }}
    />
  </SelectHolder>
);

export default FilterDocs;

import React, { useEffect, useState } from 'react';
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SATableColumn,
  SAText,
  SAPagination,
  SACard,
  SATable,
  SAButton,
} from '@saux/design-system-react';
import AWSAppSyncClient from 'aws-appsync';
import {
  FileNameWrapper,
  Row,
  Column,
  FileIcon,
  DocumentsTableWrapper,
  IconHolder,
  TopRow,
  PaginationRow,
  TableColumn,
  SecondColumn,
  SAButtonSpacer,
  SpacedRow,
} from './DocumentsTableStyles';
import { PaginationNavigation } from '../../util/paginationHelper';
import { formatTitleCase } from '../../util/formatters';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import {
  UploadModal,
  UploadTypeModal,
  ClaimsUploadModal,
  FilterDocs,
} from '..';
import FeatureFlag from '../featureFlags';
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';
import { useFeatureFlag } from '../../util/hooks';
import { GET_CLAIMS_BY_ACCOUNT_account_items_claims_items } from './queries/__generated__/GET_CLAIMS_BY_ACCOUNT';

export interface DocumentsTableProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  documents?: Document[];
  setSpecificDocType: Function;
  policies?: Policy[] | any;
  loading?: boolean;
  error?: Error | undefined;
  claims?: GET_CLAIMS_BY_ACCOUNT_account_items_claims_items[];
  onDocumentClick?: Function;
}

export interface Document {
  fileName: string;
  fileType: string;
  classification: string;
  dateUploaded: string;
  downloadRequest: DocumentDownloadRequest;
}

export interface Claim {
  claimNumber: string;
  insuredName: string;
  lossDate: string;
  claimState: string;
}

export interface Policy {
  policyNumber: string | null | undefined;
  policyType: string | null | undefined;
}

export interface DocumentDownloadRequest {
  accountNumber: string;
  documentInfoType: 'document';
  documentKey: string;
  fileName?: string;
  mimeType?: string;
}

function processFileName(filename: string): JSX.Element {
  return (
    <Row>
      <FileIcon>
        <SAIcon size={SAIconSize.medium} icon={SAIcons.documentPDF} />
      </FileIcon>
      <FileNameWrapper>
        <SAText type="standard" text={`${filename}`} colorVariant="default" />
      </FileNameWrapper>
    </Row>
  );
}

function processClassification(classification: string): JSX.Element {
  return (
    <SAText type="standard" text={classification} colorVariant="default" />
  );
}

function processDateUploaded(dateUploaded: string): JSX.Element {
  return <SAText type="standard" text={dateUploaded} colorVariant="default" />;
}

function organizePaymentInfo(documents: Document[]): any[][] {
  const rowInfo: any[][] = [];
  documents.forEach((document: Document) => {
    const name: string = document?.fileName ?? 'Account Document';
    const adjustedName = name
      .replace(/^\d{10}\s{1}/g, '')
      .replace(/\sEM_\d\d_\d\d\d\d\s/g, ' ');
    const displayedName =
      adjustedName.length > 25
        ? `${formatTitleCase(adjustedName.substring(0, 25))}...`
        : `${formatTitleCase(adjustedName)}`;

    rowInfo.push([
      `${displayedName}`,
      `${document?.classification}`,
      `${document?.dateUploaded}`,
      document.downloadRequest,
    ]);
  });
  return rowInfo;
}

const DocumentsTable = ({
  awsAppSyncClient,
  documents,
  setSpecificDocType,
  policies,
  error,
  loading,
  claims,
  onDocumentClick,
}: DocumentsTableProps) => {
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [
    documentsTablePaginationNav,
    setDocumentsTablePaginationNav,
  ] = useState<PaginationNavigation>(PaginationNavigation.FIRST);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showClaimsUploadModal, setShowClaimsUploadModal] = useState<boolean>(
    false,
  );
  const [
    showUploadDocChoiceModal,
    setShowUploadDocChoiceModal,
  ] = useState<boolean>(false);
  const [staging, setStaging] = useState(false);

  const { flagDetails } = useFeatureFlag('UploadDoc-TypeChoiceModal');

  useEffect(() => {
    if (showUploadModal || showUploadDocChoiceModal) {
      document.body.style.overflow = 'hidden';
    } else if (!showUploadModal && !showUploadDocChoiceModal) {
      document.body.style.overflow = 'auto';
    }
  }, [showUploadModal, showUploadDocChoiceModal]);

  if (error) {
    return (
      <DocumentsTableWrapper>
        <TopRow>
          <Column>
            <SAText type="standard" text="Error" colorVariant="default" />
          </Column>
        </TopRow>
      </DocumentsTableWrapper>
    );
  }
  if (loading) {
    return (
      <DocumentsTableWrapper>
        <TopRow>
          <Column>
            <SAText type="standard" text="Loading..." colorVariant="default" />
          </Column>
        </TopRow>
      </DocumentsTableWrapper>
    );
  }

  const totalDocuments: number = documents ? documents.length : 0;
  const itemsPerPage: number = 25;
  const totalPages = Math.ceil(totalDocuments / 25);
  let currentDocuments: Document[] = [];

  function documentsTablePageChange(newCurrentPageIndex: number) {
    simpleGTMDataLayer({
      event: `Documents-Pagination-click`,
      event_action: 'Documents Pagination Click',
      event_category: 'Documents',
      event_label: 'Documents Pagination Click',
    });
    simpleGTMDataLayer({
      event: `Snowplow-Documents-Pagination-click`,
      event_action: 'Snowplow Documents Pagination Click',
      event_category: 'Snowplow Documents',
      event_label: 'Snowplow Documents Pagination Click',
    });
    if (newCurrentPageIndex === 0) {
      setDocumentsTablePaginationNav(PaginationNavigation.FIRST);
    } else if (totalPages - 1 === newCurrentPageIndex) {
      setDocumentsTablePaginationNav(PaginationNavigation.LAST);
    } else if (selectedPageIndex < newCurrentPageIndex) {
      setDocumentsTablePaginationNav(PaginationNavigation.NEXT);
    } else if (selectedPageIndex > newCurrentPageIndex) {
      setDocumentsTablePaginationNav(PaginationNavigation.PREVIOUS);
    }
    setSelectedPageIndex(newCurrentPageIndex);
  }

  let startIndex: number = 0;
  let lastIndex: number = 25;

  switch (documentsTablePaginationNav) {
    case PaginationNavigation.LAST:
      startIndex = selectedPageIndex * 25;
      lastIndex = selectedPageIndex * 25 + 25;
      currentDocuments = (documents ?? []).slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.PREVIOUS:
      startIndex = selectedPageIndex * 25;
      lastIndex = selectedPageIndex * 25 + 25;
      currentDocuments = (documents ?? []).slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.NEXT:
      startIndex = selectedPageIndex * 25;
      lastIndex = selectedPageIndex * 25 + 25;
      currentDocuments = (documents ?? []).slice(startIndex, lastIndex);
      break;
    case PaginationNavigation.FIRST:
    default:
      currentDocuments = (documents ?? []).slice(startIndex, lastIndex);
      break;
  }

  function processDocumentDownload(downloadRequest: any): JSX.Element {
    return (
      <IconHolder
        onClick={() => {
          simpleGTMDataLayer({
            event: `Documents-Download-click`,
            event_action: 'Documents Download Click',
            event_category: 'Documents',
            event_label: 'Documents Download Click',
          });
          simpleGTMDataLayer({
            event: `Snowplow-Documents-Download-click`,
            event_action: 'Snowplow Documents Download Click',
            event_category: 'Snowplow Documents',
            event_label: 'Snowplow Documents Download Click',
          });
          if (onDocumentClick) {
            if (downloadRequest?.documentKey?.includes('Claim')) {
              onDocumentClick({
                document_key: downloadRequest?.documentKey,
                fileName: downloadRequest?.fileName,
                mimeType: downloadRequest?.mimeType,
              });
            } else {
              onDocumentClick({
                document_key: downloadRequest?.documentKey,
              });
            }
          }
        }}
      >
        <DownloadIcon />
      </IconHolder>
    );
  }

  const columns: SATableColumn[] = [
    {
      align: 'left',
      name: 'File Name',
      process: processFileName,
    },
    {
      align: 'center',
      name: 'Classification',
      process: processClassification,
    },
    {
      align: 'center',
      name: 'Date Uploaded',
      process: processDateUploaded,
    },
    {
      align: 'right',
      name: 'Download',
      process: processDocumentDownload,
    },
  ];
  const documentsData: any[][] = organizePaymentInfo(currentDocuments);

  return (
    <DocumentsTableWrapper>
      {showUploadDocChoiceModal && (
        <UploadTypeModal
          closeModal={() => setShowUploadDocChoiceModal(false)}
          showUploadModal={() => {
            setShowUploadModal(true);
            setShowUploadDocChoiceModal(false);
          }}
          showClaimsUploadModal={() => {
            setShowClaimsUploadModal(true);
            setShowUploadDocChoiceModal(false);
          }}
        />
      )}
      {showClaimsUploadModal && (
        <ClaimsUploadModal
          awsAppSyncClient={awsAppSyncClient}
          closeModal={() => setShowClaimsUploadModal(false)}
          claims={claims}
        />
      )}
      {showUploadModal && (
        <UploadModal
          awsAppSyncClient={awsAppSyncClient}
          staging={staging}
          showModalHandler={setShowUploadModal}
          stagingHandler={setStaging}
          policies={policies}
        />
      )}
      <TopRow>
        {window.innerWidth > 576 ? (
          <>
            <Column>
              <SAText
                type="standard"
                text={`${totalDocuments} documents`}
                colorVariant="default"
              />
            </Column>
            <SecondColumn>
              {!showUploadModal &&
                !showClaimsUploadModal &&
                !showUploadDocChoiceModal && (
                  <FilterDocs
                    setSpecificDocType={setSpecificDocType}
                    documentsTablePageChange={documentsTablePageChange}
                  />
                )}
              <FeatureFlag flagName="UploadDocuments">
                <SAButtonSpacer>
                  <SAButton
                    label="UPLOAD DOCUMENTS"
                    variant="primary"
                    onClick={() => {
                      simpleGTMDataLayer({
                        event: 'uploadDocumentClick',
                        event_action: 'Button Click',
                        event_category: window.location.pathname,
                        event_label: 'Open Upload Documents Modal',
                      });
                      simpleGTMDataLayer({
                        event: 'Snowplow-uploadDocumentClick',
                        event_action: 'Snowplow Button Click',
                        event_category: window.location.pathname,
                        event_label: 'Snowplow Open Upload Documents Modal',
                      });
                      if (flagDetails && flagDetails.enabled === true) {
                        setShowUploadDocChoiceModal(true);
                      } else {
                        setShowUploadModal(true);
                      }
                    }}
                  />
                </SAButtonSpacer>
              </FeatureFlag>
            </SecondColumn>
          </>
        ) : (
          <>
            <FeatureFlag flagName="UploadDocuments">
              <SAButton
                fullWidth
                label="UPLOAD DOCUMENTS"
                variant="primary"
                onClick={() => {
                  simpleGTMDataLayer({
                    event: 'uploadDocumentClick',
                    event_action: 'Button Click',
                    event_category: window.location.pathname,
                    event_label: 'Open Upload Documents Modal',
                  });
                  simpleGTMDataLayer({
                    event: 'Snowplow-uploadDocumentClick',
                    event_action: 'Snowplow Button Click',
                    event_category: window.location.pathname,
                    event_label: 'Snowplow Open Upload Documents Modal',
                  });
                  if (flagDetails && flagDetails.enabled === true) {
                    setShowUploadDocChoiceModal(true);
                  } else {
                    setShowUploadModal(true);
                  }
                }}
              />
            </FeatureFlag>
            {!showUploadModal &&
              !showClaimsUploadModal &&
              !showUploadDocChoiceModal && (
                <FilterDocs
                  setSpecificDocType={setSpecificDocType}
                  documentsTablePageChange={documentsTablePageChange}
                />
              )}
            <SpacedRow>
              <SAText
                type="standard"
                text={`${totalDocuments} documents`}
                colorVariant="default"
              />
            </SpacedRow>
          </>
        )}
      </TopRow>
      {!documents || documents.length === 0 ? (
        <SAText
          type="standard"
          text="No documents exist for your account"
          colorVariant="default"
        />
      ) : (
        <SACard variant="minimal">
          <Row>
            <TableColumn>
              <SATable
                columns={columns}
                data={documentsData}
                variant="table-to-listview"
              />
            </TableColumn>
          </Row>
          <PaginationRow>
            <div className="documents-table-pagination">
              <Column>
                <SAPagination
                  currentPageIndex={selectedPageIndex}
                  totalItems={totalDocuments}
                  itemsPerPage={itemsPerPage}
                  onChange={documentsTablePageChange}
                />
              </Column>
            </div>
          </PaginationRow>
        </SACard>
      )}
    </DocumentsTableWrapper>
  );
};

DocumentsTable.defaultProps = {
  documents: [],
  loading: false,
  error: undefined,
};

export default DocumentsTable;

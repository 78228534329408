import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { DocumentsTable, ProductTour } from './components';

interface DocumentsProps {
  session: {
    accountNumber: string;
    awsAppSyncClient: AWSAppSyncClient<any>;
    useNavigation: Function;
  };
}

const Documents = ({ session }: DocumentsProps) => {
  const { useNavigation } = session;

  return (
    <div className="Documents">
      <ProductTour
        accountNumber={session.accountNumber}
        awsAppSyncClient={session.awsAppSyncClient}
        useNavigation={useNavigation}
      />
      <DocumentsTable
        accountNumber={session.accountNumber}
        awsAppSyncClient={session.awsAppSyncClient}
      />
    </div>
  );
};

export default Documents;

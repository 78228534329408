import React, { useState } from 'react';

import {
  UploadClaimDocsBody,
  UploadDocsWrap,
  UploadDropArea,
  DropAreaTextWrap,
  UploadIcon,
  ErrorIcon,
  DropAreaDesktopText,
  DropAreaResponsiveText,
  DropAreaErrorText,
  SelectFilesWrap,
  CancelButtonRow,
  CancelButton,
} from './UploadClaimDocsStyles';

import {
  ClaimsModalHeader,
  UploadFilesButton,
  UploadStatus,
  fileFormats,
  getFileExtn,
  filterFileList,
  getUniqueFiles,
} from '../util';

export interface SelectGroupObj {
  value: string;
  showError: boolean;
}

const selectGroupObjDefault = {
  value: '',
  showError: false,
} as SelectGroupObj;

export interface ClaimDocObj {
  file: File;
  relatedTo: SelectGroupObj;
  documentType: SelectGroupObj;
  status: UploadStatus;
}

export const claimDocObjDefault = (file: File) =>
  ({
    file,
    relatedTo: selectGroupObjDefault,
    documentType: selectGroupObjDefault,
    status: 'Pending',
  } as ClaimDocObj);

interface Props {
  claimNumber: string;
  unsupportedFiles: File[];
  setUnsupportedFiles: Function;
  setUploadedFiles: Function;
  closeModal: Function;
}

const UploadClaimDocs = ({
  claimNumber,
  unsupportedFiles,
  setUnsupportedFiles,
  setUploadedFiles,
  closeModal,
}: Props) => {
  const [dragHover, setDragHover] = useState<boolean>(false);

  const handleDrag = (
    e: React.DragEvent<HTMLDivElement>,
    hoverFlag: boolean,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDragHover(hoverFlag);
    setUnsupportedFiles([]);
  };

  const addFiles = (files: FileList | null) => {
    setUnsupportedFiles([]);

    if (files?.length) {
      const [supportedFiles, newUnsupportedFiles]: File[][] = filterFileList(
        files,
      );

      const uniqueFiles = getUniqueFiles(supportedFiles);

      if (uniqueFiles.length) {
        setUploadedFiles(uniqueFiles.map((file) => claimDocObjDefault(file)));
      }
      setUnsupportedFiles(getUniqueFiles(newUnsupportedFiles));
    }
  };

  return (
    <UploadClaimDocsBody>
      <ClaimsModalHeader
        closeModal={closeModal}
        title="Upload Claim Documents"
        subText={`Claim Number: ${claimNumber}`}
      />
      <UploadDocsWrap className={dragHover ? 'active' : ''}>
        <UploadDropArea
          onDrop={(e) => {
            handleDrag(e, false);
            addFiles(e.dataTransfer.files);
          }}
          onDragOver={(e) => handleDrag(e, true)}
          onDragLeave={(e) => handleDrag(e, false)}
          className={dragHover ? 'active' : ''}
        />
        <DropAreaTextWrap>
          {unsupportedFiles?.length ? <ErrorIcon /> : <UploadIcon />}
          {unsupportedFiles?.length ? (
            <>
              <DropAreaErrorText>
                Following file format(s) is not supported, please try again.
              </DropAreaErrorText>
              <DropAreaErrorText>
                {unsupportedFiles
                  .map((file: File) => getFileExtn(file))
                  .filter(
                    (extn: string, index: number, array: string[]) =>
                      array.indexOf(extn) === index,
                  )
                  .sort()
                  .join(', ')
                  .toUpperCase()}
              </DropAreaErrorText>
            </>
          ) : (
            <>
              <DropAreaDesktopText>
                Drag and Drop Files Here
              </DropAreaDesktopText>
              <DropAreaDesktopText>or</DropAreaDesktopText>
              <DropAreaResponsiveText>
                Click on the button below to upload your files.
              </DropAreaResponsiveText>
            </>
          )}
        </DropAreaTextWrap>
        <SelectFilesWrap>
          <UploadFilesButton
            label="SELECT FILES"
            onChange={(files: FileList) => addFiles(files)}
            acceptedFormats={fileFormats.join()}
          />
        </SelectFilesWrap>
      </UploadDocsWrap>
      <CancelButtonRow>
        <CancelButton onClick={() => closeModal()}>CANCEL</CancelButton>
      </CancelButtonRow>
    </UploadClaimDocsBody>
  );
};

export default UploadClaimDocs;

import styled, { css } from 'styled-components';
import customBase from '../../../util/global.theme';

import { ReactComponent as UploadSVG } from '../../../assets/images/illustration-upload-cloud.svg';
import { ReactComponent as ErrorSVG } from '../../../assets/images/Error.svg';

const breakPoint = customBase.global.breakpoints.medium;

export const UploadClaimDocsBody = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakPoint}px) {
    height: 100%;
  }
`;

export const UploadDocsWrap = styled.div`
  display: flex;
  @media screen and (min-width: ${breakPoint + 1}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 700px;
    height: 350px;
    margin: 30px;
    border: 1px solid #d2dbe7;
    border-radius: 4px;
    background-color: #ffffff;
    transition: all 0.25s;

    &.active {
      border-radius: 12px;
      border: 2px dashed #0173c6;
      background-color: #f4f6fa;
    }
  }
  @media screen and (max-width: ${breakPoint}px) {
    padding: 24px 20px 0;
    flex-direction: column;
  }
`;

export const UploadDropArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  &.active {
    z-index: 4;
    cursor: copy;
  }

  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

export const DropAreaTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${breakPoint}px) {
    width: 100%;
  }
`;

const IconStyle = css`
  display: block;
  width: 65px;
  height: auto;
  margin: 0 auto 35px;
  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

export const UploadIcon = styled(UploadSVG)`
  ${IconStyle}
`;

export const ErrorIcon = styled(ErrorSVG)`
  ${IconStyle}
`;

const DropAreaText = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #3b3b3b;
`;

export const DropAreaDesktopText = styled(DropAreaText)`
  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

export const DropAreaResponsiveText = styled(DropAreaText)`
  @media screen and (min-width: ${breakPoint + 1}px) {
    display: none;
  }
`;

export const DropAreaErrorText = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #3b3b3b;
  margin-bottom: 8px;
  text-align: center;
  :last-child {
    margin-bottom: 0;
  }
`;

export const SelectFilesWrap = styled.div`
  position: relative;
  z-index: 3;
  margin: 20px auto 0;
  @media screen and (max-width: ${breakPoint}px) {
    margin: 20px 0 0;
  }
`;

export const CancelButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px;
  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

export const CancelButton = styled.p`
  cursor: pointer;
  padding: 0 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  border-radius: 4px;
  color: #3b3b3b;
  border-radius: 4px;
  :hover {
    background: #0173c6;
    color: #ffffff;
  }
`;

import styled from 'styled-components';
import customBase from '../../util/global.theme';

import { ReactComponent as PolicySVG } from '../../assets/images/Policy.svg';
import { ReactComponent as ClaimsSVG } from '../../assets/images/Claims.svg';

const breakPoint = customBase.global.breakpoints.medium;

interface TypeSelectBlockProps {
  uploadDocType: string;
}

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const UploadTypeModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  @media screen and (min-width: ${breakPoint + 1}px) {
    border-radius: 3px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    width: 100%;
    height: 100%;
  }
`;

export const UploadTypeModalHeader = styled.div`
  display: flex;
  @media screen and (max-width: ${breakPoint}px) {
    border-bottom: 1px solid #d2dbe7;
  }
`;

export const ModalTextWrap = styled.div`
  padding: 30px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media screen and (max-width: ${breakPoint}px) {
    padding: 24px 20px;
  }
`;

export const ModalTitle = styled.p`
  margin: 0 0 8px;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #3b3b3b;
`;

export const ModalSubText = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  color: #3b3b3b;
`;

export const ModalCloseWrap = styled.div`
  padding: 20px;
  cursor: pointer;
  width: 70px;
  height: 70px;
  align-self: flex-start;
  @media screen and (min-width: ${breakPoint + 1}px) {
    display: none;
  }
`;

export const ModalTypeWrap = styled.div`
  padding: 20px 35px 70px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${breakPoint}px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const TypeSelectBlock = styled.div.attrs(
  (props: TypeSelectBlockProps) => ({
    uploadDocType: props.uploadDocType,
  }),
)<TypeSelectBlockProps>`
  display: flex;
  cursor: pointer;
  @media screen and (min-width: ${breakPoint + 1}px) {
    width: 200px;
    height: 200px;
    background: #f4f6fa;
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 35px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    border-bottom: 1px solid #d2dbe7;
    align-items: center;
  }
`;

export const PolicyBlockIcon = styled(PolicySVG)`
  @media screen and (min-width: ${breakPoint + 1}px) {
    display: block;
    width: 72px;
    height: auto;
    margin-bottom: 9px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    width: auto;
    height: 40px;
    margin: 10px 20px;
  }
`;

export const ClaimsBlockIcon = styled(ClaimsSVG)`
  @media screen and (min-width: ${breakPoint + 1}px) {
    display: block;
    width: 60px;
    height: 66px;
    margin: 7px 0 9px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    width: auto;
    height: 30px;
    margin: 15px 22px;
  }
`;

export const TypeBlockText = styled.p`
  @media screen and (min-width: ${breakPoint + 1}px) {
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    color: #3b3b3b;
    margin: 0;
  }
`;

import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { DocumentNode } from 'graphql';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import FileContent from './FileContent';
import { GET_SOURCE } from './graphql/queries/__generated__/GET_SOURCE';

const getSourceSystemCode: DocumentNode = loader(
  './graphql/queries/Get_Source_System_Code.graphql',
);

interface FileContentDataContainerProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  dispatch: any;
  name?: string;
  policy?: string;
  uploadState: number;
  file: File;
  uploadData?: any;
  uploadFlag: Function;
  spinnerOn: number;
  successCount: number;
  setSuccessCount: Function;
  totalFiles: number;
  uploadClicked: boolean;
  setUploadClicked: Function;
}

export function fetchSourceCode(
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GET_SOURCE>> {
  const queryResult: Promise<
    ApolloQueryResult<GET_SOURCE>
  > = awsAppSyncClient.query({
    query: getSourceSystemCode,
  }) as Promise<ApolloQueryResult<GET_SOURCE>>;
  return queryResult;
}

const FileContentDataContainer = ({
  awsAppSyncClient,
  dispatch,
  name,
  policy,
  uploadState,
  file,
  uploadData,
  uploadFlag,
  spinnerOn,
  successCount,
  setSuccessCount,
  totalFiles,
  uploadClicked,
  setUploadClicked,
}: FileContentDataContainerProps) => {
  const [sourceSystemCode, setSourceSystemCode] = useState<string>('GWPL');
  const [sourceSystemCodeError, setSourceSystemCodeError] = useState<boolean>(
    false,
  );

  useEffect(() => {
    fetchSourceCode(awsAppSyncClient)
      .then(async (apolloQueryResult: ApolloQueryResult<GET_SOURCE>) => {
        if (apolloQueryResult.data.sourceSystem)
          setSourceSystemCode(apolloQueryResult.data.sourceSystem);
      })
      .catch((err: Error) => {
        console.log('GET_SOURCE_SYSTEM_CODE ERROR: ', err);
        setSourceSystemCodeError(true);
      });
  }, [awsAppSyncClient]);

  return (
    <FileContent
      awsAppSyncClient={awsAppSyncClient}
      dispatch={dispatch}
      name={name}
      policy={policy}
      uploadState={uploadState}
      file={file}
      uploadData={uploadData}
      uploadFlag={uploadFlag}
      spinnerOn={spinnerOn}
      successCount={successCount}
      setSuccessCount={setSuccessCount}
      totalFiles={totalFiles}
      uploadClicked={uploadClicked}
      setUploadClicked={setUploadClicked}
      sourceSystemCode={sourceSystemCode}
      sourceSystemCodeError={sourceSystemCodeError}
    />
  );
};

FileContentDataContainer.defaultProps = {
  name: undefined,
  policy: undefined,
  uploadData: undefined,
};

export default FileContentDataContainer;

import React, { useState } from 'react';

import {
  UploadClaimDocsFormBody,
  UploadDocsFormWrap,
  FooterButtonRow,
  FooterButtonGroup,
  FooterButton,
  FooterCancelButton,
  FooterUploadButton,
  FooterUploadButtonSpinner,
} from './UploadClaimDocsFormStyles';

import { ClaimDocObj, claimDocObjDefault } from '../uploadClaimDocs';

import {
  ClaimsModalHeader,
  UploadFilesButton,
  ClaimDocCard,
  UnsupportedFileCard,
  FloatingAlert,
  fileFormats,
  filterFileList,
  getUniqueFiles,
} from '../util';

import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

interface Props {
  claimNumber: string;
  uploadedFiles: ClaimDocObj[];
  setUploadedFiles: Function;
  clearList: Function;
  unsupportedFiles: File[];
  setUnsupportedFiles: Function;
  closeModal: Function;
  relatedToOptions: string[];
  documentTypeOptions: string[];
  uploadDocuments: Function;
}

const UploadClaimDocsForm = ({
  claimNumber,
  uploadedFiles,
  setUploadedFiles,
  clearList,
  unsupportedFiles,
  setUnsupportedFiles,
  closeModal,
  relatedToOptions,
  documentTypeOptions,
  uploadDocuments,
}: Props) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [uploadCompleteAlert, setUploadCompleteAlert] = useState<boolean>(
    false,
  );

  const removeFile = (fileIndex: number) => {
    if (uploadedFiles.length === 1) {
      setUnsupportedFiles([]);
    }

    setUploadedFiles(
      uploadedFiles.filter(
        (fileData: ClaimDocObj, index: number) => index !== fileIndex,
      ),
    );
  };

  const removeUnsupportedFile = (fileIndex: number) => {
    setUnsupportedFiles(
      unsupportedFiles.filter(
        (file: File, index: number) => index !== fileIndex,
      ),
    );
  };

  const addFiles = (files: FileList | null) => {
    if (files?.length) {
      const [supportedFiles, newUnsupportedFiles]: File[][] = filterFileList(
        files,
      );

      const uniqueSupportedFiles = getUniqueFiles(supportedFiles);

      if (uniqueSupportedFiles.length) {
        const newFileObjArr: ClaimDocObj[] = [];
        [
          ...uploadedFiles,
          ...uniqueSupportedFiles.map((file) => claimDocObjDefault(file)),
        ].forEach((x: ClaimDocObj) => {
          if (!newFileObjArr.find((y) => y.file.name === x.file.name)) {
            newFileObjArr.push(x);
          }
        });

        setUploadedFiles(newFileObjArr);
      }
      setUnsupportedFiles(
        getUniqueFiles([...unsupportedFiles, ...newUnsupportedFiles]),
      );
    }
  };

  const handleFileDataChange = (fileObj: ClaimDocObj, fileIndex: number) => {
    const uploadedFilesCopy = [...uploadedFiles];
    uploadedFilesCopy[fileIndex] = fileObj;
    setUploadedFiles(uploadedFilesCopy);
  };

  const validateFileOptions = () => {
    let validityFlag = true;
    const uploadedFilesCopy = uploadedFiles.map((fileObj: ClaimDocObj) => {
      const { relatedTo, documentType } = fileObj;
      if (
        documentType.value === '' ||
        documentType.value === documentTypeOptions[0]
      ) {
        validityFlag = false;
        documentType.showError = true;
      }

      return {
        ...fileObj,
        relatedTo,
        documentType,
      };
    });

    setUploadedFiles(uploadedFilesCopy);

    return validityFlag;
  };

  const handleSubmit = async () => {
    if (validateFileOptions()) {
      simpleGTMDataLayer({
        event: 'Documents-claimsUploadDocumentClick',
        event_action: 'Documents Claims Upload Button Click',
        event_category: 'Documents Claims',
        event_label: 'Claims Upload Documents',
      });
      simpleGTMDataLayer({
        event: `Snowplow-Documents-claimsUploadDocumentClick`,
        event_action: 'Snowplow Documents Claims Upload Button Click',
        event_category: 'Snowplow Documents Claims',
        event_label: 'Snowplow Claims Upload Documents',
      });

      setUploading(true);
      await uploadDocuments()
        .then(() => {
          setUploadComplete(true);
          setUploadCompleteAlert(true);
        })
        .catch(() => {
          setUploadComplete(true);
          setNetworkError(true);
        });
      setUploading(false);
    }
  };

  return (
    <UploadClaimDocsFormBody>
      <ClaimsModalHeader
        uploadComplete={uploadComplete}
        disabled={uploading}
        closeModal={closeModal}
        title="Upload Claim Documents"
        subText={`Claim Number: ${claimNumber}`}
      />
      <UploadDocsFormWrap>
        {uploadedFiles.map((fileData: ClaimDocObj, index: number) => (
          <ClaimDocCard
            disabled={uploading}
            key={fileData.file.name}
            fileData={fileData}
            updateFileData={(newFileData: ClaimDocObj) =>
              handleFileDataChange(newFileData, index)
            }
            removeFile={() => removeFile(index)}
            relatedToOptions={relatedToOptions}
            documentTypeOptions={documentTypeOptions}
          />
        ))}
        {unsupportedFiles.map((file: File, index: number) => (
          <UnsupportedFileCard
            key={file.name}
            file={file}
            removeFile={() => removeUnsupportedFile(index)}
          />
        ))}
      </UploadDocsFormWrap>
      {networkError && uploadComplete && (
        <FloatingAlert
          isSuccess={false}
          title="Error during documents upload"
          description="Error during documents upload"
          close={() => setUploadedFiles([])}
        />
      )}
      {uploadCompleteAlert && (
        <FloatingAlert
          isSuccess
          title="Your Upload is Complete"
          description="It may take several minutes for the document to display."
          close={() => setUploadCompleteAlert(false)}
        />
      )}
      <FooterButtonRow>
        <FooterButtonGroup>
          {!uploadComplete && (
            <FooterUploadButton
              disabled={uploading}
              onClick={() => (uploading ? null : handleSubmit())}
            >
              {uploading ? (
                <>
                  <FooterUploadButtonSpinner />
                  UPLOADING
                </>
              ) : (
                'UPLOAD DOCUMENTS'
              )}
            </FooterUploadButton>
          )}
          <FooterCancelButton
            uploadComplete={uploadComplete}
            disabled={uploading}
            onClick={() => (uploading && !uploadComplete ? null : closeModal())}
          >
            CANCEL
          </FooterCancelButton>
        </FooterButtonGroup>
        {!uploadComplete && (
          <FooterButtonGroup>
            <UploadFilesButton
              disabled={uploading}
              label="ADD MORE FILES"
              onChange={(files: FileList) =>
                uploading ? null : addFiles(files)
              }
              acceptedFormats={fileFormats.join()}
            />
            <FooterButton
              disabled={uploading}
              onClick={() => {
                if (!uploading) {
                  setUnsupportedFiles([]);
                  clearList();
                }
              }}
            >
              CLEAR LIST
            </FooterButton>
          </FooterButtonGroup>
        )}
      </FooterButtonRow>
    </UploadClaimDocsFormBody>
  );
};

export default UploadClaimDocsForm;

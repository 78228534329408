import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  UPDATE_SPECIAL_EVENT_TRACKING,
  UPDATE_SPECIAL_EVENT_TRACKINGVariables,
} from '../../generated/UPDATE_SPECIAL_EVENT_TRACKING';
import ProductTour from './ProductTour';

const updateProductTourStatusMutation = loader(
  '../../graphql/mutations/Update_Special_Event_Tracking.graphql',
);

interface ProductTourDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
  useNavigation: Function;
}

const updateProductTourStatus = (
  awsAppSyncClient: AWSAppSyncClient<any>,
  accountNumber: string | undefined,
) => {
  const variables: UPDATE_SPECIAL_EVENT_TRACKINGVariables = {
    account_number: accountNumber,
    field_name: 'c360_product_tour',
  } as UPDATE_SPECIAL_EVENT_TRACKINGVariables;

  return awsAppSyncClient.mutate({
    mutation: updateProductTourStatusMutation,
    variables,
  }) as Promise<ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>>;
};

const ProductTourDataContainer = ({
  accountNumber,
  awsAppSyncClient,
  useNavigation,
}: ProductTourDataContainerProps) => {
  const [productTourCompleted, setProductTourCompleted] = useState<boolean>(
    false,
  );
  useEffect(() => {
    if (productTourCompleted) {
      updateProductTourStatus(awsAppSyncClient, accountNumber)
        .then(
          (queryResult: ApolloQueryResult<UPDATE_SPECIAL_EVENT_TRACKING>) => {
            if (
              queryResult?.data?.specialEventTracking?.response !== 'Success'
            ) {
              throw new Error();
            }
          },
        )
        .catch((err: Error) => {
          console.error('UPDATE_PRODUCT_TOUR_STATUS_MUTATE ERROR: ', err);
        });
    }
  }, [productTourCompleted]);

  return (
    <ProductTour
      useNavigation={useNavigation}
      setProductTourCompleted={setProductTourCompleted}
    />
  );
};

export default ProductTourDataContainer;

import styled from 'styled-components';
import customBase from '../../../util/global.theme';

import { ReactComponent as SpinnerSVG } from '../../../assets/images/spinner-light.svg';

const breakPoint = customBase.global.breakpoints.medium;

export const UploadClaimDocsFormBody = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakPoint}px) {
    height: 100%;
  }
`;

export const UploadDocsFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 30px;
  border: 1px solid #d2dbe7;
  border-radius: 4px;
  overflow-y: auto;
  @media screen and (min-width: ${breakPoint + 1}px) {
    width: 700px;
    height: 350px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    width: calc(100% - 40px);
    flex-grow: 1;
    margin: 24px 20px 0;
    flex-direction: column;
  }
`;

export const FooterButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 30px 30px;
  @media screen and (min-width: ${breakPoint + 1}px) {
    justify-content: space-between;
  }
  @media screen and (max-width: ${breakPoint}px) {
    padding: 15px 20px 0;
    flex-direction: column;
  }
`;

export const FooterButtonGroup = styled.div`
  @media screen and (min-width: ${breakPoint + 1}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const FooterButton = styled.p<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => (disabled ? 'opacity: 0.6;' : '')}
  padding: 0 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  border-radius: 4px;
  color: #3b3b3b;
  @media screen and (min-width: ${breakPoint + 1}px) {
    margin-right: 6px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    margin-bottom: 16px;
    line-height: 53px;
    font-size: 19px;
    width: 100%;
    text-align: center;
  }
  :hover {
    background: #0173c6;
    color: #ffffff;
  }
`;

export const FooterCancelButton = styled(FooterButton)<{
  uploadComplete: boolean;
  disabled: boolean;
}>`
  ${({ uploadComplete, disabled }) =>
    disabled && !uploadComplete ? 'display: none;' : ''}
  ${({ uploadComplete }) =>
    uploadComplete ? 'opacity: 1; cursor: pointer;' : ''}
  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

export const FooterUploadButton = styled(FooterButton)`
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #4d840b;
  margin-right: 0;
  :hover {
    background: #4d840b;
  }
`;

export const FooterUploadButtonSpinner = styled(SpinnerSVG)`
  margin-right: 8px;
`;

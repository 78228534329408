import React from 'react';

import UploadClaimDocsForm from './UploadClaimDocsForm';

import { ClaimDocObj } from '../uploadClaimDocs';

interface Props {
  claimNumber: string;
  uploadedFiles: ClaimDocObj[];
  setUploadedFiles: Function;
  clearList: Function;
  unsupportedFiles: File[];
  setUnsupportedFiles: Function;
  closeModal: Function;
  relatedToOptions: string[];
  documentTypeOptions: string[];
  uploadDocuments: Function;
}

const DataContainer = ({
  claimNumber,
  uploadedFiles,
  setUploadedFiles,
  clearList,
  unsupportedFiles,
  setUnsupportedFiles,
  closeModal,
  relatedToOptions,
  documentTypeOptions,
  uploadDocuments,
}: Props) => (
  <UploadClaimDocsForm
    claimNumber={claimNumber}
    uploadedFiles={uploadedFiles}
    setUploadedFiles={setUploadedFiles}
    clearList={clearList}
    unsupportedFiles={unsupportedFiles}
    setUnsupportedFiles={setUnsupportedFiles}
    closeModal={closeModal}
    relatedToOptions={relatedToOptions}
    documentTypeOptions={documentTypeOptions}
    uploadDocuments={uploadDocuments}
  />
);

export default DataContainer;

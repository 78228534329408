export const fileFormats = [
  '.avi',
  '.bmp',
  '.csv',
  '.doc',
  '.gif',
  '.htm',
  '.html',
  '.jpeg',
  '.jpg',
  '.mdi',
  '.mov',
  '.mp3',
  '.mpeg',
  '.mpg',
  '.pdf',
  '.png',
  '.ppt',
  '.pptx',
  '.rtf',
  '.tif',
  '.tiff',
  '.txt',
  '.wav',
  '.wma',
  '.xls',
  '.xlsx',
];

export const getFileExtn = (file: File) =>
  file.name.split('.').pop() || 'undefined';

export const filterFileList = (files: FileList) => {
  const supportedFiles: File[] = [];
  const unsupportedFiles: File[] = [];
  for (let i = 0; i < files.length; i += 1) {
    const fileExtn = `.${getFileExtn(files[i])}`;
    if (fileFormats.indexOf(fileExtn) !== -1) {
      supportedFiles.push(files[i]);
    } else {
      unsupportedFiles.push(files[i]);
    }
  }
  return [supportedFiles, unsupportedFiles];
};

export const getUniqueFiles = (files: File[]) => {
  const uniqueFiles: File[] = [];

  files.forEach((x: File) => {
    if (!uniqueFiles.find((y: File) => y.name === x.name)) uniqueFiles.push(x);
  });

  return uniqueFiles;
};

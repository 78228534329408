import styled from 'styled-components';
import customBase from '../../../../util/global.theme';

const breakPoint = customBase.global.breakpoints.medium;

export const ModalHeader = styled.div`
  display: flex;
  @media screen and (max-width: ${breakPoint}px) {
    border-bottom: 1px solid #d2dbe7;
  }
`;

export const ModalTextWrap = styled.div`
  padding: 30px 30px 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media screen and (max-width: ${breakPoint}px) {
    padding: 24px 20px;
  }
`;

export const ModalTitle = styled.p`
  margin: 0 0 8px;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: #3b3b3b;
`;

export const ModalSubText = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  color: #3b3b3b;
`;

export const ModalCloseWrap = styled.div<{
  uploadComplete: boolean;
  disabled: boolean;
}>`
  ${({ uploadComplete, disabled }) =>
    disabled && !uploadComplete ? 'display: none;' : ''}
  padding: 20px;
  cursor: pointer;
  width: 70px;
  height: 70px;
  align-self: flex-start;
  @media screen and (min-width: ${breakPoint + 1}px) {
    display: none;
  }
`;

import React from 'react';

import {
  SelectClaimBody,
  ClaimsListWrap,
  ClaimSelectBlock,
  ClaimBlockTitle,
  ClaimBlockTextRow,
  TextRowLabel,
  TextRowText,
  NoClaimsWrap,
  NoClaimsIcon,
  NoClaimsText,
  CancelButtonRow,
  CancelButton,
} from './SelectClaimViewStyles';

import { ClaimsModalHeader } from '../util';

export interface ClaimObj {
  id: string;
  name: string;
  location: string;
  dateOfLoss: string;
  policyNumber: string;
  incidents: Array<string | undefined> | undefined;
}

interface Props {
  closeModal: Function;
  claims: ClaimObj[];
  selectClaim: Function;
}

const SelectClaim = ({ closeModal, claims, selectClaim }: Props) => {
  return (
    <SelectClaimBody>
      <ClaimsModalHeader
        closeModal={closeModal}
        title="Select a Claim"
        subText="Choose a claim you want to upload the supporting documents."
      />
      {claims?.length ? (
        <ClaimsListWrap>
          {claims.map((claim: ClaimObj) => (
            <ClaimSelectBlock key={claim.id} onClick={() => selectClaim(claim)}>
              <ClaimBlockTitle>{claim.id}</ClaimBlockTitle>
              <ClaimBlockTextRow>
                <TextRowLabel>Insured Name:</TextRowLabel>
                <TextRowText>{claim.name}</TextRowText>
              </ClaimBlockTextRow>
              <ClaimBlockTextRow>
                <TextRowLabel>Loss Location:</TextRowLabel>
                <TextRowText>{claim.location}</TextRowText>
              </ClaimBlockTextRow>
              <ClaimBlockTextRow>
                <TextRowLabel>Date of Loss:</TextRowLabel>
                <TextRowText>{claim.dateOfLoss}</TextRowText>
              </ClaimBlockTextRow>
            </ClaimSelectBlock>
          ))}
        </ClaimsListWrap>
      ) : (
        <NoClaimsWrap>
          <NoClaimsIcon />
          <NoClaimsText>
            You don&#8217;t have any active claim on file.
          </NoClaimsText>
        </NoClaimsWrap>
      )}
      <CancelButtonRow>
        <CancelButton onClick={() => closeModal()}>CANCEL</CancelButton>
      </CancelButtonRow>
    </SelectClaimBody>
  );
};

export default SelectClaim;

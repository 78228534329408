const cleanUpPrefixes = (formattedFileName: string) => {
  let tempFileName: string = formattedFileName;

  const prefixes = [
    'Homeowners',
    'Personal',
    'Auto',
    'Business',
    'Umbrella',
    'Farm',
    'and Ranch',
    'Commercial',
    'owners',
    'Owners',
    'BP7',
    'WC',
    'Dwelling',
    'Fire',
    'CU',
    'CA7Comm',
    '00000000',
  ];

  prefixes.forEach((prefix) => {
    if (tempFileName.includes(prefix)) {
      tempFileName = tempFileName?.replace(prefix, '');
    }
  });

  return tempFileName;
};
const cleanUpSuffixes = (formattedFileName: string) => {
  let tempFileName: string = formattedFileName;

  const suffixes = [
    '.avi',
    '.bmp',
    '.csv',
    '.doc',
    '.gif',
    '.htm',
    '.html',
    '.jpeg',
    '.jpg',
    '.mdi',
    '.mov',
    '.mp3',
    '.mpeg',
    '.mpg',
    '.pdf',
    '.png',
    '.ppt',
    '.pptx',
    '.rtf',
    '.tif',
    '.tiff',
    '.txt',
    '.wav',
    '.wma',
    '.xls',
    '.xlsx',
  ];

  suffixes.forEach((suffix) => {
    if (tempFileName.includes(suffix)) {
      tempFileName = tempFileName?.replace(suffix, '');
    }
  });

  return tempFileName;
};

const cleanUpTrailingHyphen = (formattedFileName: string) => {
  const trailingHyphenIgnoreWhitespaceRegex = new RegExp(/-\s*$/, 'g');

  return formattedFileName.replace(trailingHyphenIgnoreWhitespaceRegex, '');
};

const cleanUpSpaces = (formattedFileName: string) =>
  formattedFileName.replace(/\s+/g, ' ').trim();

const formatFileName = (
  fileName: string | undefined | null,
  isClaimDoc?: boolean,
) => {
  if (fileName) {
    let formattedFileName: string = fileName;

    formattedFileName = cleanUpPrefixes(formattedFileName);
    formattedFileName = cleanUpTrailingHyphen(formattedFileName);
    formattedFileName = cleanUpSpaces(formattedFileName);
    if (isClaimDoc) formattedFileName = cleanUpSuffixes(formattedFileName);

    return formattedFileName;
  }

  return '-';
};

export default formatFileName;

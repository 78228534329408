import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
// eslint-disable-next-line
import MobileModal from './mobileModal/MobileModal';
// eslint-disable-next-line
import DesktopModal from './desktopModal/DesktopModal';
import { GET_POLICIES_BY_ACCOUNT_account_items_policies_items } from './queries/__generated__/GET_POLICIES_BY_ACCOUNT';
import {
  Backdrop,
  DesktopContainer,
  ResponsiveContainer,
} from './UploadModalStyles';

export interface ModalProperties {
  awsAppSyncClient: AWSAppSyncClient<any>;
  staging: boolean;
  showModalHandler: Function;
  stagingHandler: Function;
  policies: GET_POLICIES_BY_ACCOUNT_account_items_policies_items[];
}

export interface ModalAndReducerProps extends ModalProperties {
  data: any;
  dispatch: any;
}

export const reducer = (
  state: { fileList: any[] },
  action: { type: any; inDropZone: any; files: any },
) => {
  switch (action.type) {
    case 'SET_IN_DROP_ZONE':
      return { ...state, inDropZone: action.inDropZone };
    case 'ADD_FILE_TO_LIST':
      return { ...state, fileList: state.fileList.concat(action.files) };
    case 'CLEAR_LIST':
      return { ...state, fileList: action.files };
    case 'REMOVE_FILE':
      return {
        ...state,
        fileList: state.fileList.filter((item) => item !== action.files),
      };
    default:
      return state;
  }
};

export default ({
  awsAppSyncClient,
  staging,
  showModalHandler,
  stagingHandler,
  policies,
}: ModalProperties) => {
  const [data, dispatch] = React.useReducer(reducer, {
    inDropZone: false,
    fileList: [],
  });

  return (
    <Backdrop>
      <DesktopContainer>
        <DesktopModal
          awsAppSyncClient={awsAppSyncClient}
          staging={staging}
          showModalHandler={showModalHandler}
          stagingHandler={stagingHandler}
          policies={policies}
          data={data}
          dispatch={dispatch}
        />
      </DesktopContainer>
      <ResponsiveContainer>
        <MobileModal
          awsAppSyncClient={awsAppSyncClient}
          staging={staging}
          showModalHandler={showModalHandler}
          stagingHandler={stagingHandler}
          policies={policies}
          data={data}
          dispatch={dispatch}
        />
      </ResponsiveContainer>
    </Backdrop>
  );
};

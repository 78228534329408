/* eslint-disable */
import React, { useState, useEffect } from 'react';
import AWSAppSyncClient from 'aws-appsync';
import generateUploadDocument from '../../../util/uploadDocumentMutation/generateUploadDocument';
import { ReactComponent as PdfIcon } from '../../../assets/images/PDF-Icon.svg';
import { ReactComponent as CloseSmall } from '../../../assets/images/CloseSmallIcon.svg';
import { ReactComponent as SpinnerPrimary } from '../../../assets/images/spinner-primary.svg';
import {
  FileContentStyle,
  ButtonContainer,
  DropDownContainer,
  ErrorText,
  IconSpacing,
  IconStyling,
  StatusText,
  MessageHolder,
} from '../UploadModalStyles';

export interface UploadProps {
  awsAppSyncClient: AWSAppSyncClient<any>;
  dispatch: any;
  name?: string;
  policy?: string;
  uploadState: number;
  file: File;
  uploadData?: any;
  uploadFlag: Function;
  spinnerOn: number;
  successCount: number;
  setSuccessCount: Function;
  totalFiles: number;
  uploadClicked: boolean;
  setUploadClicked: Function;
  sourceSystemCode: string;
  sourceSystemCodeError: boolean;
}

export interface MessagesProps {
  loading: boolean;
  error: Error | undefined;
  documentError: boolean;
  finishedUpload: boolean;
  successfulUpload: boolean;
  name: string | undefined | null;
}

export const Messages: Function = ({
  loading,
  error,
  documentError,
  finishedUpload,
  successfulUpload,
  name,
}: MessagesProps) => {
  if (window.screen.availWidth <= 470 && name) {
    if (name.length > 20) {
      name = `${name?.substring(0, 20)}...`;
    }
  } else if (window.screen.availWidth > 470 && name) {
    if (name.length > 40) {
      name = `${name?.substring(0, 40)}...`;
    }
  }
  return (
    <MessageHolder>
      {loading && <StatusText>Select a policy...</StatusText>}
      {(error || documentError) && (
        <ErrorText>Error: Could Not Upload. Try Again.</ErrorText>
      )}
      {!error && !loading && !finishedUpload && (
        <StatusText>Pending</StatusText>
      )}
      {finishedUpload && successfulUpload && (
        <StatusText>Upload Complete</StatusText>
      )}
      {name}
    </MessageHolder>
  );
};

export const translateDocument = (f: Blob | any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (f instanceof Blob) {
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => {
        reject(err);
        return null;
      };
    } else {
      resolve('Error, not Blob');
      return null;
    }
  });

export default ({
  awsAppSyncClient,
  dispatch,
  name,
  policy,
  uploadState,
  file,
  uploadData,
  uploadFlag,
  spinnerOn,
  successCount,
  setSuccessCount,
  totalFiles,
  uploadClicked,
  setUploadClicked,
  sourceSystemCode,
  sourceSystemCodeError,
}: UploadProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [documentError, setDocumentError] = useState<boolean>(false);
  const [successfulUpload, setSuccess] = useState<boolean>(false);
  const [finishedUpload, setFinishedUpload] = useState<boolean>(false);
  let docData: any;

  const validateAndUpload = async () => {
    if (policy !== 'Select Policy') {
      uploadFlag(202);
      docData = await translateDocument(file);
      docData &&
        (await generateUploadDocument(
          awsAppSyncClient,
          policy,
          name,
          file.type,
          docData,
          sourceSystemCode,
        ).then((result) => {
          if (result.uploaded === true) {
            uploadFlag(200);
            setSuccess(true);
            setFinishedUpload(true);
            setSuccessCount(successCount - 1);
          } else {
            setFinishedUpload(true);
            uploadFlag(404);
            setError(new Error());
            setDocumentError(true);
          }
          setUploadClicked(false);
        }));
    }
  };

  useEffect(() => {
    if (
      uploadState &&
      uploadState > 0 &&
      !successfulUpload &&
      uploadClicked &&
      !sourceSystemCodeError
    ) {
      validateAndUpload();
    }
  }, [uploadState]);

  useEffect(() => {
    if (policy !== 'Select Policy' && file) {
      uploadState + 1;
      setLoading(false);
    }
  }, [policy]);

  return (
    <FileContentStyle>
      <IconSpacing>
        <IconStyling>
          <PdfIcon />
        </IconStyling>
        <Messages
          loading={loading}
          error={error}
          documentError={documentError}
          finishedUpload={finishedUpload}
          successfulUpload={successfulUpload}
          name={name}
        />
      </IconSpacing>
      <DropDownContainer>
        {finishedUpload && successfulUpload ? (
          <span />
        ) : spinnerOn !== 0 &&
          spinnerOn !== 200 &&
          spinnerOn !== 404 &&
          !(error || documentError) ? (
          <ButtonContainer>
            <SpinnerPrimary />
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            {uploadData && !error ? (
              <ButtonContainer />
            ) : (
              <IconStyling
                test-attr="remove-file-button"
                onClick={() => {
                  if (totalFiles > 1) {
                    dispatch({
                      type: 'REMOVE_FILE',
                      inDropZone: false,
                      files: file,
                    });
                  } else {
                    dispatch({
                      type: 'CLEAR_LIST',
                      inDropZone: false,
                      files: [],
                    });
                  }
                  uploadFlag(0);
                }}
              >
                <CloseSmall />
              </IconStyling>
            )}
          </ButtonContainer>
        )}
      </DropDownContainer>
    </FileContentStyle>
  );
};

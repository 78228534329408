import React, { useState } from 'react';

import { Backdrop, ClaimsUploadModalWrap } from './ClaimsUploadModalStyles';

import SelectClaimView, { ClaimObj } from './selectClaimView';
import UploadClaimDocs, { ClaimDocObj } from './uploadClaimDocs';
import UploadClaimDocsForm from './uploadClaimDocsForm';

interface Props {
  closeModal: Function;
  claims: ClaimObj[];
  uploadedFiles: ClaimDocObj[];
  setUploadedFiles: Function;
  relatedToOptions: string[];
  documentTypeOptions: string[];
  uploadDocuments: Function;
  selectedClaim: ClaimObj | null;
  setSelectedClaim: Function;
}

const ClaimsUploadModal = ({
  closeModal,
  claims,
  uploadedFiles,
  setUploadedFiles,
  relatedToOptions,
  documentTypeOptions,
  uploadDocuments,
  selectedClaim,
  setSelectedClaim,
}: Props) => {
  const [unsupportedFiles, setUnsupportedFiles] = useState<File[]>([]);

  return (
    <Backdrop>
      <ClaimsUploadModalWrap>
        {!selectedClaim && (
          <SelectClaimView
            closeModal={closeModal}
            claims={claims}
            selectClaim={(claim: ClaimObj) => setSelectedClaim(claim)}
          />
        )}

        {selectedClaim && !uploadedFiles?.length && (
          <UploadClaimDocs
            claimNumber={selectedClaim.id}
            setUploadedFiles={(files: ClaimDocObj[]) => setUploadedFiles(files)}
            unsupportedFiles={unsupportedFiles}
            setUnsupportedFiles={(array: File[]) => setUnsupportedFiles(array)}
            closeModal={closeModal}
          />
        )}

        {selectedClaim && !!uploadedFiles?.length && (
          <UploadClaimDocsForm
            claimNumber={selectedClaim.id}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={(files: ClaimDocObj[]) => setUploadedFiles(files)}
            unsupportedFiles={unsupportedFiles}
            setUnsupportedFiles={(array: File[]) => setUnsupportedFiles(array)}
            clearList={() => setUploadedFiles([])}
            closeModal={closeModal}
            relatedToOptions={relatedToOptions}
            documentTypeOptions={documentTypeOptions}
            uploadDocuments={uploadDocuments}
          />
        )}
      </ClaimsUploadModalWrap>
    </Backdrop>
  );
};

export default ClaimsUploadModal;

import React from 'react';
import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';

import {
  ModalHeader,
  ModalTextWrap,
  ModalTitle,
  ModalSubText,
  ModalCloseWrap,
} from './ClaimsModalHeaderStyles';

interface Props {
  uploadComplete?: boolean;
  disabled?: boolean;
  closeModal: Function;
  title: string;
  subText: string;
}

const ClaimsModalHeader = ({
  uploadComplete = false,
  disabled = false,
  closeModal,
  title,
  subText,
}: Props) => {
  return (
    <ModalHeader>
      <ModalTextWrap>
        <ModalTitle>{title}</ModalTitle>
        <ModalSubText>{subText}</ModalSubText>
      </ModalTextWrap>
      <ModalCloseWrap
        uploadComplete={uploadComplete}
        disabled={disabled}
        onClick={() => (disabled && !uploadComplete ? null : closeModal())}
      >
        <SAIcon icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" />
      </ModalCloseWrap>
    </ModalHeader>
  );
};

ClaimsModalHeader.defaultProps = {
  uploadComplete: false,
  disabled: false,
};

export default ClaimsModalHeader;

import styled from 'styled-components';
import customBase from '../../../../util/global.theme';

const breakPoint = customBase.global.breakpoints.medium;

export const UploadFilesButtonWrap = styled.label<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => (disabled ? 'opacity: 0.6;' : '')}
  position: relative;
`;

export const ButtonLabel = styled.p`
  padding: 0 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  background-color: #0173c6;
  border-radius: 4px;
  color: #ffffff;
  @media screen and (max-width: ${breakPoint}px) {
    line-height: 53px;
    font-size: 19px;
    width: 100%;
    text-align: center;
  }
`;

export const ButtonOverlay = styled.input.attrs(() => ({
  type: 'file',
  name: 'file',
  multiple: true,
}))`
  display: none;
`;

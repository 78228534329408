import styled, { css } from 'styled-components';

import customBase from '../../../../util/global.theme';

import { ReactComponent as WarningSVG } from '../../../../assets/images/Warning.svg';
import { ReactComponent as SuccessSVG } from '../../../../assets/images/Success-v2.svg';
import { ReactComponent as CloseSVG } from '../../../../assets/images/CloseSmallIcon.svg';

const breakPoint = customBase.global.breakpoints.medium;

export const FloatingAlertWrap = styled.div<{ success: 'success' | null }>`
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  border-left: 5px solid;
  background-color: ${(success) => (success ? '#edf2e6' : '#fff6e9')};
  border-color: ${(success) => (success ? '#4d840b' : '#f5a524')};
  padding: 15px;
  bottom: 190px;
  z-index: 9;
  left: calc(50% - 230px);
  @media screen and (min-width: ${breakPoint + 1}px) {
    width: 460px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    width: calc(100% - 56px);
    left: 28px;
    bottom: ${(isSuccess) => (isSuccess ? '23' : '214')}px;
  }
`;

const FloatingAlertIcon = css`
  display: block;
  width: 25px;
  height: auto;
  margin-right: 14px;
`;

export const WarningIcon = styled(WarningSVG)`
  ${FloatingAlertIcon}
`;

export const SuccessIcon = styled(SuccessSVG)`
  ${FloatingAlertIcon}
`;

export const AlertTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 14px;
`;

export const AlertTextTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #256c00;
  margin-bottom: 4px;
`;

export const AlertTextDescription = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: #256c00;
`;

export const CloseButton = styled(CloseSVG)<{ success: 'success' | null }>`
  display: block;
  cursor: pointer;
  width: 30px;
  height: auto;
  padding: 8px;

  path {
    stroke: ${(success) => (success ? '#264205' : '#7a5212')};
  }
`;

import React, { useState, useRef, ChangeEvent } from 'react';

import { SelectGroupObj } from '../../../uploadClaimDocs';

import {
  SelectGroupWrap,
  GroupLabel,
  ElementWrap,
  SelectElement,
  ChevronUp,
  ChevronDown,
} from './SelectGroupStyles';

interface Props {
  label: string;
  fileObj: SelectGroupObj;
  disabled: boolean;
  options: string[];
  onChange: Function;
}

const SelectGroup = ({
  label,
  fileObj,
  disabled,
  options,
  onChange,
}: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const selectRef = useRef(null);

  const handleChange = (value: string) => {
    onChange(value);
    (document.querySelector('select:focus') as HTMLElement)?.blur();
  };

  return (
    <SelectGroupWrap>
      <GroupLabel active={isActive} error={fileObj.showError}>
        {label}
      </GroupLabel>
      <ElementWrap active={isActive} error={fileObj.showError}>
        <SelectElement
          ref={selectRef}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          value={fileObj.value}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            handleChange(e.target.value)
          }
          disabled={disabled}
        >
          {options.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </SelectElement>
        {isActive ? <ChevronUp /> : <ChevronDown />}
      </ElementWrap>
    </SelectGroupWrap>
  );
};

export default SelectGroup;

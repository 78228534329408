import React from 'react';

import {
  ClaimDocCardWrap,
  FileDetailsWrap,
  FileDetailsIcon,
  FileDetailsTextWrap,
  FileStatus,
  FileName,
  CloseIcon,
} from '../ClaimDocCardStyles';

interface Props {
  file: File;
  removeFile: Function;
}

const UnsupportedFileCard = ({ file, removeFile }: Props) => {
  return (
    <ClaimDocCardWrap>
      <FileDetailsWrap>
        <FileDetailsIcon />
        <FileDetailsTextWrap>
          <FileStatus className="error">
            Failed: File Type is Not Supported
          </FileStatus>
          <FileName uploaded>{file.name}</FileName>
        </FileDetailsTextWrap>
      </FileDetailsWrap>
      <CloseIcon hidden={null} disabled={false} onClick={() => removeFile()} />
    </ClaimDocCardWrap>
  );
};

export default UnsupportedFileCard;

import React, { useState, useEffect } from 'react';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
} from '@saux/design-system-react';
import { ReactComponent as ChevronUp } from '../../../assets/images/chevron-up.svg';
import { ReactComponent as ChevronDownUnbolded } from '../../../assets/images/chevron-down.svg';
import { ReactComponent as SpinnerLight } from '../../../assets/images/spinner-light.svg';
import { ReactComponent as Success } from '../../../assets/images/Success.svg';
import { ReactComponent as CloseSmall } from '../../../assets/images/CloseSmallIcon.svg';
import DropArea from '../dropArea/DropArea';
import FileContent from '../fileContent';
// eslint-disable-next-line
import { ModalAndReducerProps } from '../UploadModal';
import {
  SelectFiles,
  Container,
  StagingModal,
  HeaderText,
  SubtitleTextNote,
  SubtitleText,
  PolicyDropBox,
  ErrorText,
  StagingBox,
  SuccessToast,
  CheckSizing,
  SuccessText,
  DelayMessage,
  CloseSizing,
  UploadModalContainer,
  StagingModalContainer,
  UploadModal,
  Row,
  ResponsiveDocumentSelector,
  MobileWordingRow,
  MobileDropDown,
  MobileHeaderRow,
  MobileOptions,
  MobileContent,
  MobileStagingGap,
  NestedMobileOptions,
  MobileGrayedUploadButton,
  MobileUploadButton,
  MobileGrayedSelectButton,
  MobileSelectButton,
  MobileButtonHolder,
  UploadMobileButtonHolder,
  CloseIcon,
  HeaderHolder,
  MobileGrayedClearListButton,
} from '../UploadModalStyles';
import { simpleGTMDataLayer } from '../../../util/GTMHelpers';

interface SpinnerOnProps {
  spinnerOn: number;
}

interface AddFilesButtonProps {
  spinnerOn: number;
  chooseFiles: Function;
  setPolicy: Function;
  setSelectedPolicySubString: Function;
  uploadFlag: Function;
}

interface ClearListProps {
  spinnerOn: number;
  dispatch: any;
  uploadFlag: Function;
  setPolicy: Function;
  setSelectedPolicySubString: Function;
}

export const AddFilesButton: Function = ({
  spinnerOn,
  chooseFiles,
  setPolicy,
  setSelectedPolicySubString,
  uploadFlag,
}: AddFilesButtonProps) => {
  if (spinnerOn === 202) {
    return (
      <MobileGrayedSelectButton test-attr="disabled-add-more-files-option">
        ADD MORE FILES
      </MobileGrayedSelectButton>
    );
  }
  return (
    <MobileSelectButton htmlFor="file">
      ADD MORE FILES
      <SelectFiles
        test-attr="upload-select-files-option"
        type="file"
        name="file"
        id="file"
        multiple
        onChange={(e) => {
          if (spinnerOn === 200) {
            setPolicy('Select Policy');
            setSelectedPolicySubString('Select Policy');
            uploadFlag(0);
          }
          chooseFiles(e.target.files);
        }}
        accept=".pdf, image/*"
      />
    </MobileSelectButton>
  );
};

export const UploadingText: Function = ({ spinnerOn }: SpinnerOnProps) => {
  if (spinnerOn === 202) {
    return (
      <Row>
        UPLOADING...
        <SpinnerLight />
      </Row>
    );
  }
  return <>UPLOAD DOCUMENTS</>;
};

export const ClearList: Function = ({
  spinnerOn,
  dispatch,
  uploadFlag,
  setPolicy,
  setSelectedPolicySubString,
}: ClearListProps) => {
  if (spinnerOn !== 202) {
    return (
      <MobileButtonHolder>
        <SAButton
          test-attr="upload-clear-option"
          fullWidth
          label="CLEAR LIST"
          onClick={() => {
            dispatch({
              type: 'CLEAR_LIST',
              inDropZone: false,
              files: [],
            });
            uploadFlag(0);
            setPolicy('Select Policy');
            setSelectedPolicySubString('Select Policy');
          }}
          variant="link-medium"
        />
      </MobileButtonHolder>
    );
  }
  return (
    <MobileButtonHolder>
      <MobileGrayedClearListButton>CLEAR LIST</MobileGrayedClearListButton>
    </MobileButtonHolder>
  );
};

export default ({
  awsAppSyncClient,
  staging,
  showModalHandler,
  stagingHandler,
  policies,
  data,
  dispatch,
}: ModalAndReducerProps) => {
  const [selectedPolicy, setPolicy] = useState('Select Policy');
  const [selectedPolicySubString, setSelectedPolicySubString] = useState(
    'Select Policy',
  );
  const [policyError, flagPolicyError] = useState<boolean>(false);
  const [upload, setUpload] = useState<number>(0);
  const [uploadClicked, setUploadClicked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [spinnerOn, uploadFlag] = useState<number>(0);
  const [successFlag, setSuccessFlag] = useState<boolean>(false);
  const [successCount, setSuccessCount] = useState<number>(1);

  const chooseFiles = (e: any) => {
    let files: any[] = [];
    for (let i = 0; i < e.length; i += 1) {
      files.push(e[i]);
    }
    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f: { name: any }) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));

      dispatch({
        type: 'ADD_FILE_TO_LIST',
        inDropZone: false,
        files,
      });
      stagingHandler(true);
    }
  };

  const files = data.fileList;

  useEffect(() => {
    if (successCount === 0) {
      setSuccessFlag(true);
    }
  }, [successCount]);

  useEffect(() => {
    if (successFlag === true) {
      setTimeout(() => {
        setSuccessFlag(false);
      }, 5000);
    }
  }, [successFlag]);

  return (
    <Container id="Modal">
      {staging ? (
        <StagingModalContainer id="StagingModal">
          <StagingModal>
            <MobileHeaderRow>
              <MobileStagingGap>
                <HeaderHolder>
                  <HeaderText>Upload Documents</HeaderText>
                  <CloseIcon
                    test-attr="upload-cancel-option"
                    onClick={() => {
                      showModalHandler(false);
                      simpleGTMDataLayer({
                        event: 'Documents-uploadDocumentCancel',
                        event_action: 'Documents Upload Button Cancel',
                        event_category: 'Documents',
                        event_label: 'Upload Documents',
                      });
                      simpleGTMDataLayer({
                        event: `Snowplow-Documents-uploadDocumentCancel`,
                        event_action: 'Snowplow Documents Upload Button Cancel',
                        event_category: 'Snowplow Documents',
                        event_label: 'Snowplow Upload Documents',
                      });
                    }}
                  >
                    <SAIcon
                      icon={SAIcons.x}
                      size={SAIconSize.medium}
                      colorVariant="dark"
                    />
                  </CloseIcon>
                </HeaderHolder>
                <>
                  <SubtitleTextNote>Note: </SubtitleTextNote>
                  <SubtitleText>
                    This area is not to be used for uploading claims related
                    documents.
                  </SubtitleText>
                  <MobileWordingRow />
                </>
              </MobileStagingGap>
              <>
                <ResponsiveDocumentSelector>
                  <MobileDropDown
                    test-attr="policy-dropdown-button"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    {selectedPolicySubString}
                    {open ? <ChevronUp /> : <ChevronDownUnbolded />}
                  </MobileDropDown>
                </ResponsiveDocumentSelector>
                {open && (
                  <MobileOptions>
                    <NestedMobileOptions>
                      {policies.map((item) => {
                        return (
                          <MobileContent
                            key={item.policy_number}
                            id={item.policy_number}
                            test-attr="policy-dropdown-option"
                            onClick={() => {
                              setPolicy(item.policy_number);
                              setSelectedPolicySubString(
                                `${
                                  item.policy_type
                                }...${item.policy_number.substring(
                                  item.policy_number.length - 4,
                                  item.policy_number.length,
                                )}`,
                              );
                              setOpen(false);
                            }}
                          >
                            {`${
                              item.policy_type
                            }...${item.policy_number.substring(
                              item.policy_number.length - 4,
                              item.policy_number.length,
                            )}`}
                          </MobileContent>
                        );
                      })}
                    </NestedMobileOptions>
                  </MobileOptions>
                )}
              </>
              <PolicyDropBox>
                {policyError && selectedPolicySubString === 'Select Policy' && (
                  <ErrorText>Error: Select Policy</ErrorText>
                )}
              </PolicyDropBox>
            </MobileHeaderRow>
            <StagingBox>
              {data.fileList &&
                data.fileList.map((f: File) => {
                  return (
                    <FileContent
                      awsAppSyncClient={awsAppSyncClient}
                      name={f.name}
                      dispatch={dispatch}
                      policy={selectedPolicy}
                      uploadState={upload}
                      file={f}
                      uploadFlag={uploadFlag}
                      spinnerOn={spinnerOn}
                      successCount={successCount}
                      setSuccessCount={setSuccessCount}
                      totalFiles={data.fileList.length}
                      uploadClicked={uploadClicked}
                      setUploadClicked={setUploadClicked}
                      key={f.lastModified}
                    />
                  );
                })}
            </StagingBox>
            <>
              <UploadMobileButtonHolder>
                {!files.length ? (
                  <MobileGrayedUploadButton>
                    UPLOAD DOCUMENTS
                  </MobileGrayedUploadButton>
                ) : (
                  <MobileUploadButton
                    test-attr="upload-document-option"
                    onClick={() => {
                      setUploadClicked(true);
                      if (selectedPolicy === 'Select Policy') {
                        flagPolicyError(true);
                      } else {
                        simpleGTMDataLayer({
                          event: 'Documents-policyUploadDocumentClick',
                          event_action: 'Documents Policy Upload Button Click',
                          event_category: 'Documents Policy',
                          event_label: 'Policy Upload Documents',
                        });
                        simpleGTMDataLayer({
                          event: `Snowplow-Documents-policyUploadDocumentClick`,
                          event_action:
                            'Snowplow Documents Policy Upload Button Click',
                          event_category: 'Snowplow Documents Policy',
                          event_label: 'Snowplow Policy Upload Documents',
                        });
                        if (spinnerOn !== 404) {
                          flagPolicyError(false);
                          setUpload(upload + 1);
                          if (files) {
                            uploadFlag(files.length);
                            setSuccessCount(files.length);
                          }
                        }
                      }
                    }}
                  >
                    <UploadingText spinnerOn={spinnerOn} />
                  </MobileUploadButton>
                )}
              </UploadMobileButtonHolder>
            </>
            <>
              <MobileButtonHolder>
                <MobileButtonHolder />
                <AddFilesButton
                  spinnerOn={spinnerOn}
                  chooseFiles={chooseFiles}
                  setPolicy={setPolicy}
                  setSelectedPolicySubString={setSelectedPolicySubString}
                  uploadFlag={uploadFlag}
                />
              </MobileButtonHolder>
              <ClearList
                spinnerOn={spinnerOn}
                dispatch={dispatch}
                stagingHandler={stagingHandler}
                uploadFlag={uploadFlag}
                setPolicy={setPolicy}
                setSelectedPolicySubString={setSelectedPolicySubString}
              />
            </>
            {successFlag && (
              <SuccessToast>
                <CheckSizing>
                  <Success />
                </CheckSizing>
                <div>
                  <SuccessText>Your upload is complete</SuccessText>
                  <DelayMessage>
                    It may take several minutes for the document to display
                  </DelayMessage>
                </div>
                <CloseSizing
                  test-attr="remove-success-message-option"
                  onClick={() => {
                    setSuccessFlag(false);
                  }}
                >
                  <CloseSmall />
                </CloseSizing>
              </SuccessToast>
            )}
          </StagingModal>
        </StagingModalContainer>
      ) : (
        <UploadModalContainer id="UploadModal">
          <UploadModal>
            <div>
              <HeaderHolder>
                <HeaderText>Upload Documents</HeaderText>
                <CloseIcon
                  test-attr="upload-cancel-option"
                  onClick={() => {
                    showModalHandler(false);
                  }}
                >
                  <SAIcon
                    icon={SAIcons.x}
                    size={SAIconSize.medium}
                    colorVariant="dark"
                  />
                </CloseIcon>
              </HeaderHolder>
              <div>
                <SubtitleTextNote>Note: </SubtitleTextNote>
                <SubtitleText>
                  This area is not to be used for uploading claims related
                  documents.
                </SubtitleText>
              </div>
              <DropArea
                stagingHandler={stagingHandler}
                data={data}
                dispatch={dispatch}
              />
            </div>
          </UploadModal>
        </UploadModalContainer>
      )}
    </Container>
  );
};

import React from 'react';

import SelectGroup from './selectGroup';

import { ClaimDocObj } from '../../uploadClaimDocs';

import {
  ClaimDocCardWrap,
  FileDetailsWrap,
  FileDetailsIcon,
  FileDetailsTextWrap,
  FileStatus,
  FileName,
  CloseIcon,
  SpinnerIcon,
} from './ClaimDocCardStyles';

export type UploadStatus = 'Pending' | 'Uploading' | 'Upload Complete';

interface Props {
  disabled: boolean;
  fileData: ClaimDocObj;
  updateFileData: Function;
  removeFile: Function;
  relatedToOptions: string[];
  documentTypeOptions: string[];
}

const ClaimDocCard = ({
  disabled,
  fileData,
  updateFileData,
  removeFile,
  relatedToOptions,
  documentTypeOptions,
}: Props) => {
  const handleSelectChange = (
    value: string,
    field: 'relatedTo' | 'documentType',
  ) => {
    updateFileData({
      ...fileData,
      [field]: {
        value,
        showError: false,
      },
    });
  };

  const disabledFlag = disabled || fileData.status !== 'Pending';

  return (
    <ClaimDocCardWrap>
      <FileDetailsWrap>
        <FileDetailsIcon />
        <FileDetailsTextWrap>
          {fileData.relatedTo.showError || fileData.documentType.showError ? (
            <FileStatus className="error">
              Error: All fields are required.
            </FileStatus>
          ) : (
            <FileStatus>{fileData.status}</FileStatus>
          )}
          <FileName uploaded={fileData.status === 'Upload Complete'}>
            {fileData.file.name}
          </FileName>
        </FileDetailsTextWrap>
      </FileDetailsWrap>
      {fileData.status !== 'Upload Complete' && (
        <>
          <SelectGroup
            label="Related To"
            fileObj={fileData.relatedTo}
            disabled={disabledFlag}
            options={relatedToOptions}
            onChange={(value: string) =>
              disabledFlag ? null : handleSelectChange(value, 'relatedTo')
            }
          />
          <SelectGroup
            label="Document Type"
            fileObj={fileData.documentType}
            disabled={disabledFlag}
            options={documentTypeOptions}
            onChange={(value: string) =>
              disabledFlag ? null : handleSelectChange(value, 'documentType')
            }
          />
          {fileData.status === 'Pending' && (
            <CloseIcon
              hidden={disabled ? 'hidden' : null}
              disabled={fileData.status !== 'Pending'}
              onClick={() => (disabledFlag ? null : removeFile())}
            />
          )}
          {fileData.status === 'Uploading' && <SpinnerIcon />}
        </>
      )}
    </ClaimDocCardWrap>
  );
};

export default ClaimDocCard;

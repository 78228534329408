import React, { useEffect } from 'react';

import {
  FloatingAlertWrap,
  WarningIcon,
  SuccessIcon,
  AlertTextWrap,
  AlertTextTitle,
  AlertTextDescription,
  CloseButton,
} from './FloatingAlertStyles';

interface Props {
  isSuccess: boolean;
  title: string;
  description: string;
  close: Function;
}

const FloatingAlert = ({ isSuccess, title, description, close }: Props) => {
  useEffect(() => {
    if (isSuccess) {
      setTimeout(close, 10000);
    }
  }, []);

  return (
    <FloatingAlertWrap success={isSuccess ? 'success' : null}>
      {isSuccess ? <SuccessIcon /> : <WarningIcon />}
      <AlertTextWrap>
        <AlertTextTitle>{title}</AlertTextTitle>
        <AlertTextDescription>{description}</AlertTextDescription>
      </AlertTextWrap>
      <CloseButton
        success={isSuccess ? 'success' : null}
        onClick={() => close()}
      />
    </FloatingAlertWrap>
  );
};

export default FloatingAlert;

import { SAButton, SAText } from '@saux/design-system-react';
import React, { useState, useEffect } from 'react';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Step,
  TooltipRenderProps,
} from 'react-joyride';
import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlag } from '../../util/hooks';
import ToolTipWrapper, {
  SkipButton,
  ToolTipContent,
  ToolTipSkipNext,
  ToolTipTitle,
} from './ProductTour.styles';

export interface ProductTourState {
  stepIndex: number;
  run: boolean;
}

interface StepContent {
  title: string;
  body: string;
}

interface TourProps {
  useNavigation: Function;
  setProductTourCompleted: Function;
}

interface ProductTourSession {
  currentStep: number;
  totalSteps: number;
  displayTour: boolean;
  currentPage: string;
}

export const updateProductTourSession = (updateData: any) =>
  sessionStorage.setItem('productTourSession', JSON.stringify(updateData));

export const Tooltip = (
  tour: ProductTourState,
  productTourSession: ProductTourSession,
) => {
  const stepText: { [key: string]: StepContent } = {
    DocumentsTab: {
      title: 'Looking for an email notice or need to upload a document?',
      body: 'You can find these on the Documents page.',
    },
  };

  return ({
    step,
    closeProps,
    primaryProps,
    tooltipProps,
  }: TooltipRenderProps) => {
    const { title } = stepText[step.content as string];
    const { body } = stepText[step.content as string];

    const currentStep: string = (
      productTourSession.currentStep +
      tour.stepIndex +
      1
    ).toString();
    const totalSteps: string = productTourSession.totalSteps.toString();

    return (
      <ToolTipWrapper {...tooltipProps}>
        <ToolTipContent>
          <SAText
            text={`${currentStep}/${totalSteps}`}
            type="standard"
            weight="bold"
          />
          <ToolTipTitle>
            <SAText text={title} type="heading-3" weight="bold" />
          </ToolTipTitle>
          <SAText text={body} type="paragraph" />
        </ToolTipContent>
        <ToolTipSkipNext test-attr="tooltip-next-button">
          <SkipButton>
            <SAButton
              test-attr="documents-product-tour-skip"
              variant="link-medium"
              label="SKIP"
              {...closeProps}
            />
          </SkipButton>
          <SAButton variant="medium" label="NEXT" {...primaryProps} />
        </ToolTipSkipNext>
      </ToolTipWrapper>
    );
  };
};

export const handleJoyrideCallback = (
  productTourSession: ProductTourSession,
  tour: ProductTourState,
  setTour: Function,
  setProductTourCompleted: Function,
) => {
  return (data: CallBackProps) => {
    const { action, index, type } = data;
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      document.body.style.overflow = 'auto';

      // Update state to advance the tour
      if (action !== ACTIONS.CLOSE) {
        updateProductTourSession({
          ...productTourSession,
          currentStep: productTourSession.currentStep + 1,
        });
        setTour({
          ...tour,
          stepIndex: index + 1,
        });
        simpleGTMDataLayer({
          event: `NextButton-click`,
          event_action: 'Next Button Click',
          event_category: 'Next',
          event_label: 'Next Button Click',
          event_category_snowplow: 'Snowplow Next',
        });
      } else {
        setProductTourCompleted(true);
        updateProductTourSession({
          ...productTourSession,
          displayTour: false,
        });
        setTour({
          ...tour,
          run: false,
        });
        simpleGTMDataLayer({
          event: `SkipButton-click`,
          event_action: 'Skip Button Click',
          event_category: 'Skip',
          event_label: 'Skip Button Click',
          event_category_snowplow: 'Snowplow Skip',
        });
      }
    }
  };
};

export const handleStepOverflow = (
  tour: ProductTourState,
  steps: Step[],
  productTourSession: ProductTourSession,
  useNavigation: Function,
) => {
  if (tour.stepIndex > steps.length - 1) {
    updateProductTourSession({
      ...productTourSession,
      currentPage: 'claims',
    });
    useNavigation('/claims');
  }
};

const ProductTour = ({ useNavigation, setProductTourCompleted }: TourProps) => {
  const productTourSession: ProductTourSession | string = JSON.parse(
    sessionStorage.getItem('productTourSession') ?? '""',
  );
  const { flagDetails } = useFeatureFlag('ProductTour');
  const [tour, setTour] = useState<ProductTourState>({
    stepIndex: 0,
    run:
      typeof productTourSession !== 'string'
        ? productTourSession.displayTour
        : false,
  });

  useEffect(() => {
    const buttons = document.getElementsByTagName('button');
    const documentsTab = Array.from(buttons).find(
      (button: HTMLButtonElement) => button.textContent === 'Documents',
    ) as any;

    documentsTab.scrollIntoView(false, { behavior: 'smooth' });
  }, []);

  if (
    productTourSession &&
    typeof productTourSession !== 'string' &&
    flagDetails?.enabled
  ) {
    const steps: Step[] = [
      {
        target: '.navBar',
        content: 'DocumentsTab',
        disableBeacon: true,
      },
    ];

    if (tour.run) {
      document.body.style.overflow = 'hidden';
    }

    handleStepOverflow(tour, steps, productTourSession, useNavigation);

    return (
      <Joyride
        test-attr="product-tour-joyride"
        steps={steps}
        tooltipComponent={Tooltip(tour, productTourSession)}
        run={tour.run}
        continuous
        stepIndex={tour.stepIndex}
        disableOverlayClose
        callback={handleJoyrideCallback(
          productTourSession,
          tour,
          setTour,
          setProductTourCompleted,
        )}
        disableScrolling
        styles={{
          options: {
            zIndex: 10000,
            overlayColor: 'rgba(4, 30, 65, 0.6)',
            arrowColor: 'rgb(255, 246, 233)',
          },
        }}
      />
    );
  }

  return <></>;
};

export default ProductTour;

import styled from 'styled-components';
import customBase from '../../../util/global.theme';

const breakPoint = customBase.global.breakpoints.medium;

export const ClaimsRedirectDescription = styled.p`
  color: #3b3b3b;
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  padding: 0 30px 30px;
  @media screen and (max-width: ${breakPoint}px) {
    padding: 24px 20px;
  }
`;

export const ClaimsRedirectFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  padding: 0 30px 30px;
  @media screen and (max-width: ${breakPoint}px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

const FooterButton = styled.p`
  cursor: pointer;
  padding: 0 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  border-radius: 4px;
  border: 1px solid #67a300;
  color: #67a300;
  margin-right: 20px;
  :first-child {
    margin-right: 0;
  }
  @media screen and (max-width: ${breakPoint}px) {
    margin-right: 0;
    margin-top: 16px;
    line-height: 53px;
    font-size: 19px;
    width: 100%;
    text-align: center;
    :first-child {
      margin-top: 0;
    }
  }
`;

export const CancelButton = styled(FooterButton)`
  color: #67a300;
`;

export const ContinueButton = styled(FooterButton)`
  color: #ffffff;
  background-color: #67a300;
`;

/* eslint-disable */
import { ApolloQueryResult } from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { DocumentNode } from 'graphql';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
  GET_DOCUMENTS_BY_ACCOUNT,
  GET_DOCUMENTS_BY_ACCOUNTVariables,
  GET_DOCUMENTS_BY_ACCOUNT_account_items,
  GET_DOCUMENTS_BY_ACCOUNT_account_items_payplans_items,
  GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items,
} from './queries/__generated__/GET_DOCUMENTS_BY_ACCOUNT';
import {
  GET_POLICIES_BY_ACCOUNT,
  GET_POLICIES_BY_ACCOUNTVariables,
} from './queries/__generated__/GET_POLICIES_BY_ACCOUNT';
import { formatDate } from '../../util/formatters';
import useDocumentDownload from '../../util/useDocumentDownload';
import DocumentsTable, {
  Document,
  DocumentDownloadRequest,
  Policy,
} from './DocumentsTable';
import formatFileName from './formatFileName';
import AgencyBilledQuantity from '../../sharedTypes/AgencyBilledQuantity';
import { GET_CLAIMS_BY_ACCOUNT_account_items_claims_items } from './queries/__generated__/GET_CLAIMS_BY_ACCOUNT';

const getPolicyDocuments: DocumentNode = loader(
  './queries/Get_Documents_By_Account.graphql',
);

const getClaimsDocuments: DocumentNode = loader(
  './queries/Get_Claims_Documents_List.graphql',
);

const getAccountClaims: DocumentNode = loader(
  './queries/Get_Claims_By_Account.graphql',
);

const getClaimsIncidents: DocumentNode = loader(
  './queries/Get_Claim_Incidents_By_Account.graphql',
);

const getAccountPolicies: DocumentNode = loader(
  './queries/Get_Policies_By_Account.graphql',
);

interface DocumentsTableDataContainerProps {
  accountNumber: string;
  awsAppSyncClient: AWSAppSyncClient<any>;
}

export function fetchPolicyDocuments(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>> {
  const variables: GET_DOCUMENTS_BY_ACCOUNTVariables = {
    account_number: accountNumber,
  } as GET_DOCUMENTS_BY_ACCOUNTVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>
  > = awsAppSyncClient.query({
    query: getPolicyDocuments,
    fetchPolicy: 'network-only',
    variables,
  }) as Promise<ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>>;
  return queryResult;
}

export function fetchAccountClaims(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<any>> {
  const variables = {
    account_number: accountNumber,
  };
  const queryResult: Promise<ApolloQueryResult<any>> = awsAppSyncClient.query({
    query: getAccountClaims,
    variables,
  }) as Promise<ApolloQueryResult<any>>;
  return queryResult;
}

export function fetchClaimIncidents(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
  claimNumber: string,
): Promise<ApolloQueryResult<any>> {
  const variables = {
    account_number: accountNumber,
    claim_number: claimNumber,
  };
  const queryResult: Promise<ApolloQueryResult<any>> = awsAppSyncClient.query({
    query: getClaimsIncidents,
    variables,
  }) as Promise<ApolloQueryResult<any>>;
  return queryResult;
}

export function fetchClaimsDocuments(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
  claim: any,
  index: number,
): Promise<ApolloQueryResult<any>> {
  const variables = {
    account_number: accountNumber,
    claimNumber: claim.claim.claimNumber,
    incidentId: claim.incidents.incidentsCollection[index].incident.publicID,
    name: claim.incidents.userInfo.firstName,
    given_name: claim.incidents.userInfo.lastName,
    email: claim.incidents.userInfo.email,
  };
  const queryResult: Promise<ApolloQueryResult<any>> = awsAppSyncClient.query({
    query: getClaimsDocuments,
    variables,
  }) as Promise<ApolloQueryResult<any>>;
  return queryResult;
}

export function fetchPolicies(
  accountNumber: string,
  awsAppSyncClient: AWSAppSyncClient<any>,
): Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>> {
  const variables: GET_POLICIES_BY_ACCOUNTVariables = {
    account_number: accountNumber,
  } as GET_POLICIES_BY_ACCOUNTVariables;
  const queryResult: Promise<
    ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>
  > = awsAppSyncClient.query({
    query: getAccountPolicies,
    variables,
  }) as Promise<ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>>;
  return queryResult;
}

export const agencyBilledPayplansOnly = (
  payplans: (GET_DOCUMENTS_BY_ACCOUNT_account_items_payplans_items | null)[],
): boolean => {
  return !!payplans.every(
    (payplan: GET_DOCUMENTS_BY_ACCOUNT_account_items_payplans_items | null) =>
      payplan?.billing_method === 'Agency Bill',
  );
};

export const containsSomeAgencyBilledPayplans = (
  payplans: (GET_DOCUMENTS_BY_ACCOUNT_account_items_payplans_items | null)[],
): boolean => {
  const someAgencyBilledPayplans = payplans.some(
    (payplan: GET_DOCUMENTS_BY_ACCOUNT_account_items_payplans_items | null) =>
      payplan?.billing_method === 'Agency Bill',
  );

  const agencyBilledOnly = agencyBilledPayplansOnly(payplans);

  return someAgencyBilledPayplans && !agencyBilledOnly;
};

export const processAgencyBilled = (payplans: any): AgencyBilledQuantity => {
  if (!payplans || payplans?.length === 0) return AgencyBilledQuantity.None;
  if (agencyBilledPayplansOnly(payplans)) {
    return AgencyBilledQuantity.All;
  }

  if (containsSomeAgencyBilledPayplans(payplans)) {
    return AgencyBilledQuantity.Partial;
  }

  return AgencyBilledQuantity.None;
};

export const processPolicyClassification = (
  accountDocumentsItem: GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items,
) => {
  let classification: string = 'Account';

  if (
    accountDocumentsItem.policy?.policy_type &&
    accountDocumentsItem.policy?.policy_number
  ) {
    const policyType: string = accountDocumentsItem.policy.policy_type;
    const lastFourOfPolicyNumber: string = accountDocumentsItem.policy.policy_number.substring(
      accountDocumentsItem.policy.policy_number.length - 4,
      accountDocumentsItem.policy.policy_number.length,
    );

    switch (policyType) {
      case 'Auto':
        classification = `Auto - ${lastFourOfPolicyNumber}`;
        break;
      case 'Commercial Auto':
      case 'Farmowners Auto':
        classification = 'Auto';
        break;
      case 'Home':
        if (accountDocumentsItem.policy.form) {
          const policyForm = accountDocumentsItem.policy.form;
          if (policyForm === 'DP3')
            classification = `Dwelling Fire - ${lastFourOfPolicyNumber}`;
          else if (policyForm === 'HO4')
            classification = `Renters - ${lastFourOfPolicyNumber}`;
          else if (policyForm === 'HO6')
            classification = `Condo - ${lastFourOfPolicyNumber}`;
          else classification = `Home - ${lastFourOfPolicyNumber}`;
        } else classification = `Home - ${lastFourOfPolicyNumber}`;
        break;
      case 'Commercial Umbrella':
      case 'Farmowners Umbrella':
        classification = 'Umbrella';
        break;
      case 'Umbrella':
        classification = `Umbrella - ${lastFourOfPolicyNumber}`;
        break;
      case 'Workers Comp':
        classification = "Workers' Compensation";
        break;
      case 'Farm & Ranch':
        classification = 'Farm & Ranch';
        break;
      case 'Businessowners':
        classification = "Businessowners'";
        break;
      case 'Commercial Package':
        classification = 'Commercial Package';
        break;
      default:
        break;
    }

    return classification;
  }

  return 'Policy';
};

export const arrangeDocuments = (
  accountNumber: string,
  accountDocumentsItems:
    | (GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items | null)[]
    | null
    | undefined,
  determineIfAgencyBilled: string,
  claimsDocs: any[],
): Document[] => {
  let arrangedDocs: Document[] = [];

  accountDocumentsItems?.forEach(
    (
      accountDocumentsItem: GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items | null,
    ) => {
      if (
        determineIfAgencyBilled === 'None' ||
        determineIfAgencyBilled === 'Partial'
      ) {
        if (
          accountDocumentsItem?.security_type === 'Unrestricted' &&
          accountDocumentsItem?.file_type?.toLowerCase() !== 'text/html'
        ) {
          const downloadRequest: DocumentDownloadRequest = {
            accountNumber,
            documentInfoType: 'document',
            documentKey: accountDocumentsItem?.documents_key ?? '-',
          };
          const updatedTime: string = accountDocumentsItem?.update_time ?? '-';
          const uploadDate: string | undefined = formatDate(updatedTime, '-');

          const classification = () => {
            switch (accountDocumentsItem?.classification?.toUpperCase()) {
              case 'POLICY':
                return processPolicyClassification(accountDocumentsItem);
              case 'BILLING':
                return 'Billing';
              default:
                return 'Account';
            }
          };

          const document: Document | any = {
            fileName: formatFileName(accountDocumentsItem?.file_name),
            fileType: accountDocumentsItem?.file_type ?? '-',
            classification: classification(),
            dateUploaded: uploadDate ?? '-',
            downloadRequest,
          };

          arrangedDocs.push(document);
        }
      } else {
        if (
          accountDocumentsItem?.security_type === 'Unrestricted' &&
          accountDocumentsItem?.file_type?.toLowerCase() !== 'text/html' &&
          accountDocumentsItem?.classification?.toLowerCase() !== 'billing'
        ) {
          const downloadRequest: DocumentDownloadRequest = {
            accountNumber,
            documentInfoType: 'document',
            documentKey: accountDocumentsItem?.documents_key ?? '-',
          };
          const updatedTime: string = accountDocumentsItem?.update_time ?? '-';
          const uploadDate: string | undefined = formatDate(updatedTime, '-');

          const classification = () => {
            switch (accountDocumentsItem?.classification?.toUpperCase()) {
              case 'POLICY':
                return processPolicyClassification(accountDocumentsItem);
              default:
                return 'Account';
            }
          };

          const document: Document | any = {
            fileName: formatFileName(accountDocumentsItem?.file_name),
            fileType: accountDocumentsItem?.file_type ?? '-',
            classification: classification(),
            dateUploaded: uploadDate ?? '-',
            downloadRequest,
          };

          arrangedDocs.push(document);
        }
      }
    },
  );
  claimsDocs?.forEach((claimDoc) => {
    if (claimDoc) {
      const downloadRequest: DocumentDownloadRequest = {
        accountNumber,
        documentInfoType: 'document',
        documentKey: claimDoc?.documentUrl ?? '-',
        fileName: claimDoc?.name ?? 'Claims Document',
        mimeType: claimDoc?.mimeType ?? 'text/html',
      };
      const updatedTime: string = claimDoc?.createTime ?? '-';
      const uploadDate: string | undefined = formatDate(updatedTime, '-');
      const lossDate: string | undefined = formatDate(claimDoc?.lossDate, '-');

      const document: Document | any = {
        fileName: formatFileName(claimDoc?.name, true),
        fileType: claimDoc?.mimeType ?? '-',
        classification: `Claim ${lossDate}`,
        dateUploaded: uploadDate ?? '-',
        downloadRequest,
      };

      arrangedDocs.push(document);
    }
  });

  arrangedDocs = arrangedDocs
    .sort(
      (d1, d2) =>
        new Date(d1.dateUploaded).getTime() -
        new Date(d2.dateUploaded).getTime(),
    )
    .reverse();

  return arrangedDocs;
};

export const transformData = (
  data: GET_DOCUMENTS_BY_ACCOUNT,
  accountNumber: string,
  claimsDocs: any[],
) => {
  const { account } = data;
  const accountItems:
    | (GET_DOCUMENTS_BY_ACCOUNT_account_items | null)[]
    | null
    | undefined = account?.items;
  let documents: Document[] = [];

  accountItems?.forEach(
    (accountItem: GET_DOCUMENTS_BY_ACCOUNT_account_items | null) => {
      const accountDocumentsItems:
        | (GET_DOCUMENTS_BY_ACCOUNT_account_items_account_documents_items | null)[]
        | null
        | undefined = accountItem?.account_documents?.items;

      const determineIfAgencyBilled = processAgencyBilled(
        accountItems[0]?.payplans?.items,
      );

      const arrangedDocuments: Document[] = arrangeDocuments(
        accountNumber,
        accountDocumentsItems,
        determineIfAgencyBilled,
        claimsDocs,
      );

      documents = documents.concat(arrangedDocuments);
    },
  );

  return documents;
};

export const filterDocumentsByType = (docs: Document[], docType: string) => {
  switch (docType) {
    case 'Policy':
      return docs.filter((doc: Document) => {
        return (
          doc.classification === 'Policy' ||
          doc.classification === 'Account' ||
          doc.classification === "Workers' Compensation" ||
          doc.classification === 'Farm & Ranch' ||
          doc.classification === 'Businessowners' ||
          doc.classification === 'Commercial Package' ||
          doc.classification.includes('Auto') ||
          doc.classification.includes('Dwelling Fire') ||
          doc.classification.includes('Renters') ||
          doc.classification.includes('Condo') ||
          doc.classification.includes('Home') ||
          doc.classification.includes('Umbrella')
        );
      });
    case 'Billing':
      return docs.filter((doc: Document) => {
        return doc.classification === 'Billing';
      });
    case 'Claims':
      return docs.filter((doc: Document) => {
        return doc.classification.includes('Claim ');
      });
  }
  return docs;
};

const DocumentsTableDataContainer = ({
  accountNumber,
  awsAppSyncClient,
}: DocumentsTableDataContainerProps) => {
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [claimsDocuments, setClaimsDocuments] = useState<any[]>([]);
  const [claims, setClaims] = useState<
    GET_CLAIMS_BY_ACCOUNT_account_items_claims_items[]
  >([]);
  const [incidents, setIncidents] = useState<any[]>([]);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [specificDocs, setSpecificDocs] = useState<Document[]>([]);
  const [specificDocType, setSpecificDocType] = useState<string>('All');

  const docsRefresh = document.location.pathname;

  const { onDocumentClick, documentsModal } = useDocumentDownload(
    awsAppSyncClient,
    accountNumber,
  );

  useEffect(() => {
    switch (specificDocType) {
      case 'Policy':
        return setSpecificDocs(filterDocumentsByType(documents, 'Policy'));
      case 'Billing':
        return setSpecificDocs(filterDocumentsByType(documents, 'Billing'));
      case 'Claims':
        return setSpecificDocs(filterDocumentsByType(documents, 'Claims'));
      case 'All':
      default:
        return setSpecificDocs(documents);
    }
  }, [specificDocType]);

  useEffect(() => {
    setSpecificDocs(documents);
  }, [documents]);

  useEffect(() => {
    if (incidents.length !== 0) {
      incidents.forEach((instance) => {
        for (
          let x = 0;
          x < instance?.incidents?.incidentsCollection?.length;
          x++
        ) {
          fetchClaimsDocuments(accountNumber, awsAppSyncClient, instance, x)
            .then((apolloQueryResult: ApolloQueryResult<any>) => {
              let returnedDocs =
                apolloQueryResult?.data?.account?.items[0]?.claims_documents
                  ?.documents ?? [];
              returnedDocs = returnedDocs.map((doc: any) => ({
                ...doc,
                lossDate: instance.claim.lossDate,
              }));
              setClaimsDocuments([...claimsDocuments.concat(returnedDocs)]);
            })
            .catch(() => {
              console.error('Error fetching claims docs from backend');
            });
        }
      });
    }
  }, [incidents]);

  useEffect(() => {
    claims.length &&
      claims.forEach((claim) => {
        fetchClaimIncidents(
          accountNumber,
          awsAppSyncClient,
          claim?.claimNumber ?? '',
        )
          .then((apolloQueryResult: ApolloQueryResult<any>) => {
            const incidentsCollection =
              apolloQueryResult?.data?.account?.items[0]?.claim?.incidents ??
              [];
            const userInfo = {
              firstName: apolloQueryResult?.data?.account?.items[0]?.display_name?.split(
                ' ',
              )[0],
              lastName: apolloQueryResult?.data?.account?.items[0]?.display_name?.split(
                ' ',
              )[1],
              email: apolloQueryResult?.data?.account?.items[0]?.email_address,
            };

            setIncidents([
              ...incidents,
              {
                claim,
                incidents: { incidentsCollection, userInfo },
              },
            ]);
          })
          .catch(() => {
            console.error(
              'Error fetching an account claim incident from backend',
            );
          });
      });
  }, [claims]);

  useEffect(() => {
    fetchPolicyDocuments(accountNumber, awsAppSyncClient)
      .then(
        (apolloQueryResult: ApolloQueryResult<GET_DOCUMENTS_BY_ACCOUNT>) => {
          const docs: any[] = transformData(
            apolloQueryResult.data,
            accountNumber,
            claimsDocuments.length !== 0 ? claimsDocuments : [],
          );
          setDocuments(docs);
          setLoading(false);
        },
      )
      .catch((err: Error) => {
        setLoading(false);
        setError(err);
      });
    fetchAccountClaims(accountNumber, awsAppSyncClient)
      .then((apolloQueryResult: ApolloQueryResult<any>) => {
        const claims =
          apolloQueryResult?.data?.account?.items[0]?.claims?.items ?? [];

        setClaims(claims);
      })
      .catch(() => {
        console.error('Error fetching account claims from backend');
      });
    fetchPolicies(accountNumber, awsAppSyncClient).then(
      async (apolloQueryResult: ApolloQueryResult<GET_POLICIES_BY_ACCOUNT>) => {
        if (
          apolloQueryResult &&
          apolloQueryResult.data &&
          apolloQueryResult.data.account &&
          apolloQueryResult.data.account.items &&
          apolloQueryResult.data.account.items[0] &&
          apolloQueryResult.data.account.items[0]?.policies &&
          apolloQueryResult.data.account.items[0]?.policies.items
        ) {
          const returnedPolicies: any[] =
            apolloQueryResult.data.account.items[0]?.policies.items;
          setPolicies(returnedPolicies);
        }
      },
    );
  }, [accountNumber, docsRefresh, claims, claimsDocuments]);

  return (
    <>
      {documentsModal}
      <DocumentsTable
        awsAppSyncClient={awsAppSyncClient}
        error={error}
        loading={loading}
        documents={specificDocs}
        setSpecificDocType={setSpecificDocType}
        policies={policies}
        claims={claims}
        onDocumentClick={onDocumentClick}
      />
    </>
  );
};

export default DocumentsTableDataContainer;

import { FetchResult } from '@apollo/react-hooks';
import AWSAppSyncClient from 'aws-appsync';
import { DocumentNode } from 'graphql';
import { loader } from 'graphql.macro';
import {
  UPLOAD_POLICY_DOCUMENT,
  UPLOAD_POLICY_DOCUMENTVariables,
} from '../../generated/UPLOAD_POLICY_DOCUMENT';

const uploadPolicyDocumentMutation: DocumentNode = loader(
  '../../graphql/mutations/Upload_Policy_Document.graphql',
);

export default (
  awsAppSyncClient: AWSAppSyncClient<any>,
  policy_number: any,
  file_name: any,
  mime_type: any,
  document_data: any,
  sourceSystemCode: string,
) => {
  const variables: UPLOAD_POLICY_DOCUMENTVariables = {
    document_data,
    document_type: 'letter_received',
    file_name,
    mime_type,
    policy_number,
    security_type: 'unrestricted',
    source_system_code: sourceSystemCode,
    status: 'approved',
    author: sessionStorage.getItem('userName') ?? 'Insured',
  } as UPLOAD_POLICY_DOCUMENTVariables;
  const mutationResult: Promise<FetchResult<UPLOAD_POLICY_DOCUMENT>> =
    policy_number && file_name && mime_type && document_data
      ? (awsAppSyncClient.mutate({
          mutation: uploadPolicyDocumentMutation,
          fetchPolicy: 'no-cache',
          variables,
        }) as Promise<FetchResult<UPLOAD_POLICY_DOCUMENT>>)
      : new Promise(() => {
          return new Error(
            'one or more of the user-defined fields was null or undefined',
          );
        });

  return mutationResult
    .then((result: FetchResult<UPLOAD_POLICY_DOCUMENT>) => {
      return { result, uploaded: true };
    })
    .catch((error: Error) => {
      return { error, uploaded: false };
    });
};

import { SAUXTheme } from '@saux/design-system-react';
import styled from 'styled-components';
import customBase from '../../util/global.theme';

export const DocumentsTableWrapper = styled.div``;

export const IconHolder = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TopRow = styled(Row)`
  ${({ theme }: { theme: SAUXTheme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme?.size?.spacer?.large} 0px ${theme?.size?.spacer?.large} 0px;
    justify-content: space-between;
    @media (max-width: ${customBase.global.breakpoints.small}px) {
      flex-direction: column;
    }
  `}
`;

export const PaginationRow = styled(Row)`
  justify-content: flex-end;
`;

export const FileIcon = styled(Row)`
  display: none;
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    display: flex;
  }
`;

export const FileNameWrapper = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => `
  margin-left: ${theme?.size?.spacer?.small};
  margin-top: ${theme?.size?.spacer?.xs};
`}
`;

export const Column = styled.div`
  flex-direction: column;
  margin-left: 5px;
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
`;

export const TableColumn = styled(Column)`
  width: 100%;
`;

export const SAButtonSpacer = styled.div`
  margin-top: 5px;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 25px auto 0px 0px;
`;

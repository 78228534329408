import styled, { css } from 'styled-components';
import customBase from '../../../../../util/global.theme';

import { ReactComponent as ChevUpSVG } from '../../../../../assets/images/chevron-up.svg';
import { ReactComponent as ChevDownSVG } from '../../../../../assets/images/chevron-down.svg';

const breakPoint = customBase.global.breakpoints.medium;

interface TextProps {
  active?: boolean;
  error?: boolean;
  defaultColor?: string;
}

const statusColor = ({
  active = false,
  error = false,
  defaultColor = '#666666',
}: TextProps) => {
  let color = defaultColor;
  if (active) {
    color = '#65a100';
  } else if (error) {
    color = '#b00020';
  }
  return color;
};

export const SelectGroupWrap = styled.div`
  width: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  margin-right: 12px;
  @media screen and (max-width: ${breakPoint}px) {
    width: calc(100% - 28px);
    margin: 0 14px;
    :nth-last-child(2) {
      margin-bottom: 2px;
    }
  }
`;

export const GroupLabel = styled.span<TextProps>`
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 3px;
  line-height: 18px;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #ffffff;
  color: ${(props) => statusColor(props)};
`;

export const ElementWrap = styled.div<TextProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-radius: 5px;
  flex-grow: 1;
  border-color: ${(props) =>
    statusColor({ ...props, defaultColor: '#e7e7e7' })};
`;

export const SelectElement = styled.select`
  flex-grow: 1;
  height: 48px;
  border-radius: 5px;
  font-family: 'PT Sans', sans-serif;
  padding: 0 16px;
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
`;

const SelectChevron = css`
  display: block;
  position: absolute;
  height: auto;
  width: 14px;
  right: 16px;
  top: calc(50% - 4px);
  z-index: 0;
`;

export const ChevronUp = styled(ChevUpSVG)`
  ${SelectChevron}
`;

export const ChevronDown = styled(ChevDownSVG)`
  ${SelectChevron}
`;

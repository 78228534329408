import React from 'react';
import { ReactComponent as Upload } from '../../../assets/images/illustration-upload-cloud.svg';
import {
  DropBox,
  CloudBackground,
  DropText,
  SpecialSelectButton,
  SelectFiles,
  AltWording,
  MobileWording,
  MobileWordingRow,
} from '../UploadModalStyles';

interface DropProps {
  data: any;
  dispatch: any;
  stagingHandler: Function;
}

export default ({ data, dispatch, stagingHandler }: DropProps) => {
  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
  };
  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };
  const drop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let files: any[] = [];

    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        files.push(e.dataTransfer.files[i]);
      }

      const existingFiles = data.fileList.map((f: { name: any }) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));

      dispatch({ type: 'ADD_FILE_TO_LIST', files });
      dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
      stagingHandler(true);
    }
  };
  const chooseFiles = (e: any) => {
    let files: any[] = [];
    for (let i = 0; i < e.length; i += 1) {
      files.push(e[i]);
    }
    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f: { name: any }) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));

      dispatch({ type: 'ADD_FILE_TO_LIST', files });
      dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });

      stagingHandler(true);
    }
  };
  return (
    <DropBox
      onDrop={(e) => drop(e)}
      onDragOver={(e) => dragOver(e)}
      onDragLeave={(e) => dragLeave(e)}
    >
      <AltWording>
        <CloudBackground>
          <Upload />
          <DropText>Drag and drop files here</DropText>
        </CloudBackground>
        <DropText>or</DropText>
      </AltWording>
      <MobileWordingRow />
      <MobileWording>
        Click on the button below to upload your files.
      </MobileWording>
      <SpecialSelectButton htmlFor="file">
        SELECT FILES
        <SelectFiles
          type="file"
          name="file"
          id="file"
          multiple
          onChange={(e) => {
            chooseFiles(e.target.files);
          }}
          accept=".pdf, image/*"
        />
      </SpecialSelectButton>
    </DropBox>
  );
};

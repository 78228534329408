import { SAUXTheme } from '@saux/design-system-react';
import styled, { keyframes } from 'styled-components';
import customBase from '../../util/global.theme';

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const DesktopContainer = styled.div`
  display: none;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: block;
  }
`;

export const ResponsiveContainer = styled.div`
  display: block;
  @media screen and (min-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: block;
  margin: auto;
  align-self: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    background-color: #fff;
  }
`;

export const StagingModalContainer = styled.div`
  display: block;
  flex-direction: row;
  left: 0;
  top: 0;
  max-width: 800px;
  margin: auto;
  height: 600px;
`;

export const StagingModal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      position: relative;
      display: flex;
      flex-direction: column;
      margin: auto;
      border: 1px solid #979797;
      box-shadow: 0px ${theme?.size?.radius?.medium} 32px -${theme?.size?.radius?.medium} #002038;
      padding: ${theme?.size?.spacer?.large};
      background-color: #ffffff;
      align: center;
      justify: center;
      max-width: 800px;
      align-content: center;
      justify-content: center;
      border-radius: ${theme?.size?.radius?.small};
      @media (max-width: ${customBase.global.breakpoints.medium}px) {
        box-shadow: none;
        border: none;
      }
    `;
  }}
`;

export const UploadModalContainer = styled.div`
  display: inline;
  flex-direction: row;
  left: 0;
  top: 0;
  max-width: 650px;
  margin: auto;
  max-height: 497px;
`;

export const AltWording = styled.div`
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const MobileWordingRow = styled.div`
  background-color: #e8ebf2;
  height: 2px;
  margin-top: 15px;
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const MobileWording = styled.div`
  margin: 15px;
  @media (min-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const UploadModal = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      position: relative;
      display: flex;
      flex-direction: column;
      margin: auto;
      border: 1px solid #979797;
      box-shadow: 0px ${theme?.size?.radius?.medium} 32px -${theme?.size?.radius?.medium} #002038;
      padding: ${theme?.size?.spacer?.large};
      background-color: #ffffff;
      align: center;
      justify: center;
      max-width: 650px;
      max-height: 497px;
      border-radius: ${theme?.size?.radius?.small};
      @media (max-width: ${customBase.global.breakpoints.medium}px) {
        box-shadow: none;
        border: none;
      }
    `;
  }}
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MobileHeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const SubtitleText = styled.span`
  font-size: 14px;
`;

export const SubtitleTextNote = styled.span`
  font-size: 14px;
  color: #9325b2;
  font-weight: 700;
`;

export const StagingBox = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      border: 1px solid #80b8e2;
      border-radius: ${theme?.size?.radius?.small};
      margin-top: ${theme?.size?.spacer?.large};
      overflow: auto;
      height: 398px;
      @media (min-width: ${
        customBase.global.breakpoints.xsmall + 2
      }px) and (max-width: ${customBase.global.breakpoints.small}px) {
        height: 300px;
      }
      @media (max-width: ${customBase.global.breakpoints.xsmall + 1}px) {
        height: 250px;
      }
    `;
  }}
`;

export const ButtonRow = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: ${theme?.size?.spacer?.large};
      vertical-align: middle;
    `;
  }}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const FileControl = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: row;
      height: ${theme?.size?.spacer?.large};
    `;
  }}
`;

export const CancelAnchor = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      float: right;
      font-weight: 700;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #fff;
      margin-top: -6px;
      &:hover {
        cursor: pointer;
        background-color: #0173c6;
        border: 1px solid #0173c6;
        color: white;
      }
    `;
  }}
`;

export const DesktopCancelAnchor = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      margin-left: ${theme?.size?.spacer?.large};
      float: right;
      font-weight: 700;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #fff;
      margin-top: -6px;
      &:hover {
        cursor: pointer;
        background-color: #0173c6;
        border: 1px solid #0173c6;
        color: white;
      }
    `;
  }}
`;

export const SpecialCancelAnchor = styled.div`
  display: inline-block;
  float: right;
  font-weight: 700;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #fff;
  margin-top: -12px;
  &:hover {
    cursor: pointer;
    background-color: #0173c6;
    border: 1px solid #0173c6;
    color: white;
  }
  @media (max-width: ${customBase.global.breakpoints.large + 1}px) {
    margin-top: -23px;
  }
  @media (max-width: ${customBase.global.breakpoints.medium}px) {
    display: none;
  }
`;

export const GrayedCancelAnchor = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      float: right;
      font-weight: 700;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: ${theme?.size?.radius?.small};
      color: #979ea3;
      border: 1px solid #fff;
      margin-top: -6px;
    `;
  }}
`;

export const UploadButton = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      float: right;
      font-weight: 700;
      width: 210px;
      background-color: #65a100;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #65a100;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-left: ${theme?.size?.spacer?.medium};
      margin-top: -6px;
      &:hover {
        cursor: pointer;
      }
    `;
  }}
`;

export const MobileUploadButton = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      float: right;
      font-weight: 700;
      width: 100%;
      background-color: #65a100;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #65a100;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    `;
  }}
`;

export const GrayedUploadButton = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      float: right;
      font-weight: 700;
      width: 210px;
      background-color: #979ea3;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #979ea3;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-left: ${theme?.size?.spacer?.medium};
      margin-top: -6px;
    `;
  }}
`;

export const MobileGrayedClearListButton = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      float: right;
      font-weight: 700;
      width: 100%;
      color: #979ea3;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid white;
      height: 40px;
      line-height: 40px;
      text-align: center;
    `;
  }}
`;

export const MobileGrayedUploadButton = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      float: right;
      font-weight: 700;
      width: 100%;
      background-color: #979ea3;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #979ea3;
      height: 40px;
      line-height: 40px;
      text-align: center;
    `;
  }}
`;

export const SelectButton = styled.label`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      font-weight: 700;
      background-color: #0173c6;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #0173c6;
      height: 40px;
      width: 150px;
      line-height: 40px;
      text-align: center;
      margin-left: ${theme?.size?.spacer?.medium};
      margin-top: -6px;
      &:hover {
        cursor: pointer;
      }
    `;
  }}
`;

export const HeaderHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CloseIcon = styled.div`
  margin: 7.5px 0px 7.5px 0px;
`;

export const MobileButtonHolder = styled.div`
  margin: 7.5px 0px 7.5px 0px;
`;

export const UploadMobileButtonHolder = styled.div`
  margin: 30px 0px 7.5px 0px;
`;

export const MobileSelectButton = styled.label`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      font-weight: 700;
      background-color: #0173c6;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #0173c6;
      height: 40px;
      width: 100%;
      line-height: 40px;
      text-align: center;
    `;
  }}
`;

export const SpecialSelectButton = styled.label`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      font-weight: 700;
      background-color: #0173c6;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #0173c6;
      width: 150px;
      line-height: 40px;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
      @media (max-width: ${customBase.global.breakpoints.medium}px) {
        width: 80vw;
        height: 45px;
        line-height: 45px;
      }
    `;
  }}
`;

export const GrayedSelectButton = styled.label`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      font-weight: 700;
      background-color: #979ea3;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #979ea3;
      height: 40px;
      width: 150px;
      line-height: 40px;
      text-align: center;
      margin-left: ${theme?.size?.spacer?.medium};
      margin-top: -6px;
    `;
  }}
`;

export const MobileGrayedSelectButton = styled.label`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: inline-block;
      font-weight: 700;
      background-color: #979ea3;
      color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      border: 1px solid #979ea3;
      height: 40px;
      width: 100%;
      line-height: 40px;
      text-align: center;
    `;
  }}
`;

export const StagingGap = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-right: ${theme?.size?.icon?.large};
    `;
  }}
`;

export const MobileStagingGap = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin-bottom: ${theme?.size?.spacer?.medium};
    `;
  }}
`;

export const ErrorText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #e02020;
`;

export const PolicyDropBox = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-left: ${theme?.size?.spacer?.small};
    `;
  }}
`;

export const hideHeight = keyframes`
  from {
    height: 77px;
  }
  to {
    height: 0px;
  }
`;

export const closing = keyframes`
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 30px;
    opacity: 0;
  }
`;

export const persisting = keyframes`
from {
  bottom: 30px;
  opacity: 1;
}
to {
  bottom: 30px;
  opacity: 1;
}
`;

export const SuccessToast = styled.div`
  width: 370px;
  height: 77px;
  border-radius: 7px;
  border-left: 6px solid #65a100;
  background-color: #f2f8e6;
  z-index: 1;
  padding: 12px 27px 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: ${persisting} 5s 1, ${closing} 5s 1 5s;
  opacity: 0;
  bottom: 0;
  align-self: center;
  position: absolute;
`;

export const SuccessText = styled.div`
  font-weight: 700;
  size: 16px;
  color: #65a100;
`;

export const DelayMessage = styled.div`
  color: #3b3b3b;
  size: 12px;
`;

export const CheckSizing = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: 20px;
      padding-right: ${theme?.size?.spacer?.small};
    `;
  }}
`;

export const CloseSizing = styled.div`
  height: 24px;
  &:hover {
    cursor: pointer;
  }
`;

export const DropBox = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      justify: center;
      align: center;
      border: 1px solid #80b8e2;
      border-radius: ${theme?.size?.radius?.small};
      margin-top: ${theme?.size?.spacer?.large};
      text-align: center;
      box-sizing: border-box;
      width: 100%;
      padding: ${theme?.size?.spacer?.large};
      margin-bottom: ${theme?.size?.spacer?.large};
      @media (max-width: ${customBase.global.breakpoints.medium}px) {
        border: none;
        padding: 0px;
      }
    `;
  }}
`;

export const CloudBackground = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background-color: #f4f6fa;
      border-radius: ${theme?.size?.radius?.small};
      padding: ${theme?.size?.spacer?.large} ${theme?.size?.spacer?.large} ${theme?.size?.spacer?.medium} ${theme?.size?.spacer?.large};
      box-sizing: border-box;
      width: 300px;
      margin: auto;
    `;
  }}
`;

export const DropText = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      font-size: 16px;
      font-weight: 700;
      padding: ${theme?.size?.spacer?.medium};
      margin-bottom: 3px;
    `;
  }}
`;

export const SelectFiles = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
`;

export const FileContentStyle = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: row;
      &:nth-child(even) {
        background-color: #f4f6fa;
      }
      justify-content: space-between;
      padding: ${theme?.size?.spacer?.small};
    `;
  }}
`;

export const DocumentSelector = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #d9dfeb;
      border-radius: ${theme?.size?.radius?.small};
      margin: ${theme?.size?.spacer?.small} ${theme?.size?.spacer?.small} 0px ${theme?.size?.spacer?.small};
      padding: ${theme?.size?.spacer?.small};
    `;
  }}
`;

export const ResponsiveDocumentSelector = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #d9dfeb;
      border-radius: ${theme?.size?.radius?.small};
      padding: ${theme?.size?.spacer?.small};
    `;
  }}
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  &:hover {
    cursor: pointer;
  }
`;

export const MobileDropDown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
`;

export const Options = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      max-height: 190px;
      width: 217px;
      word-wrap: break-word;
      padding-left: ${theme?.size?.spacer?.small};
      padding-right: ${theme?.size?.spacer?.small};
      position: absolute;
      z-index: 1;
    `;
  }}
`;

export const MobileOptions = styled.div`
  width: 100%;
  word-wrap: break-word;
  position: relative;
  z-index: 1;
`;

export const Content = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding: ${theme?.size?.spacer?.small};
      word-wrap: break-word;
      width: 222px;
      border: 1px solid #d9dfeb;
      background-color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      &:hover {
        cursor: pointer;
      }
    `;
  }}
`;

export const MobileContent = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding: ${theme?.size?.spacer?.small};
      word-wrap: break-word;
      width: 100%;
      border: 1px solid #d9dfeb;
      background-color: #ffffff;
      border-radius: ${theme?.size?.radius?.small};
      z-index: 1;
    `;
  }}
`;

export const IconSpacing = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      display: flex;
      flex-direction: row;
      gap: ${theme?.size?.spacer?.small};
    `;
  }}
`;

export const StatusText = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

export const NestedMobileOptions = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
`;

export const DropDownContainer = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      padding-right: ${theme?.size?.spacer?.small};
      display: flex;
      flex-direction: row;
    `;
  }}
`;

export const IconStyling = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      height: 20px;
      margin: ${theme?.size?.spacer?.xs} 11px ${theme?.size?.spacer?.xs} ${theme?.size?.spacer?.xs};
    `;
  }}
`;

export const MessageHolder = styled.div`
  ${({ theme }: { theme: SAUXTheme }) => {
    return `
      margin: ${theme?.size?.spacer?.xs};
    `;
  }}
`;

export const ButtonContainer = styled.div`
  padding-top: 20px;
`;

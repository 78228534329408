import React from 'react';

import {
  UploadFilesButtonWrap,
  ButtonLabel,
  ButtonOverlay,
} from './UploadFilesButtonStyles';

interface Props {
  disabled?: boolean;
  label: string;
  onChange: Function;
  acceptedFormats: string;
}

const UploadFilesButton = ({
  disabled = false,
  label,
  onChange,
  acceptedFormats,
}: Props) => {
  return (
    <UploadFilesButtonWrap disabled={disabled} htmlFor="ClaimsUploadDocuments">
      <ButtonLabel>{label}</ButtonLabel>
      {!disabled && (
        <ButtonOverlay
          id="ClaimsUploadDocuments"
          onChange={(e) => onChange(e.target.files)}
          accept={acceptedFormats}
        />
      )}
    </UploadFilesButtonWrap>
  );
};

UploadFilesButton.defaultProps = {
  disabled: false,
};

export default UploadFilesButton;

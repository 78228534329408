import styled, { css } from 'styled-components';
import customBase from '../../../../util/global.theme';

import { ReactComponent as FileSVG } from '../../../../assets/images/File.svg';
import { ReactComponent as CloseSVG } from '../../../../assets/images/CloseSmallIcon.svg';
import { ReactComponent as SpinnerSVG } from '../../../../assets/images/spinner-primary.svg';

const breakPoint = customBase.global.breakpoints.medium;

export const ClaimDocCardWrap = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: 1px solid #d9dfeb;
  @media screen and (min-width: ${breakPoint + 1}px) {
    align-items: center;
    min-height: 75px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    flex-direction: column;
  }
`;

export const FileDetailsWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  @media screen and (max-width: ${breakPoint}px) {
    margin: 16px 0;
  }
`;

export const FileDetailsIcon = styled(FileSVG)`
  display: block;
  width: 16px;
  height: auto;
  margin: 0 20px 0;
  @media screen and (max-width: ${breakPoint}px) {
    margin: 0 8px 0 16px;
  }
`;

export const FileDetailsTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const FileStatus = styled.p`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #0173c6;
  margin: 0;

  &.error {
    color: #cc1d33;
  }
`;

export const FileName = styled.p<{ uploaded: boolean }>`
  font-size: 13px;
  line-height: 16px;
  color: #3b3b3b;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 248px;
  @media screen and (min-width: ${breakPoint + 1}px) {
    width: ${({ uploaded }) => (uploaded ? '570' : '162')}px;
    margin-right: 12px;
  }
`;

export const CardCloseBtnCss = css`
  display: block;
  width: 32px;
  height: auto;
  padding: 8px;
  margin-right: 12px;
  @media screen and (max-width: ${breakPoint}px) {
    position: absolute;
    top: 16px;
    right: 0;
  }
`;

export const CloseIcon = styled(CloseSVG)<{
  hidden: 'hidden' | null;
  disabled: boolean;
}>`
  ${CardCloseBtnCss}
  ${({ hidden }) => (hidden ? 'opacity: 0;' : '')}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  path {
    stroke: #666666;
  }
`;

export const SpinnerIcon = styled(SpinnerSVG)`
  ${CardCloseBtnCss}
  width: 21px;
  padding: 0;
  margin: 0 16px 0 7px;
`;

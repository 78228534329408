import React, { useState } from 'react';
import { navigate } from '@reach/router';

import { SAIcon, SAIcons, SAIconSize } from '@saux/design-system-react';

import {
  Backdrop,
  UploadTypeModalWrap,
  UploadTypeModalHeader,
  ModalTextWrap,
  ModalTitle,
  ModalSubText,
  ModalCloseWrap,
  ModalTypeWrap,
  TypeSelectBlock,
  PolicyBlockIcon,
  ClaimsBlockIcon,
  TypeBlockText,
} from './UploadTypeModal.styles';

import ClaimsRedirectView from './claimsRedirectView';

import { simpleGTMDataLayer } from '../../util/GTMHelpers';
import { useFeatureFlagEffect } from '../../util/hooks';
import getConfig, { Env } from '../../aws_exports';

const config = getConfig(process.env.REACT_APP_ENV as Env);

interface Props {
  closeModal: Function;
  showUploadModal: Function;
  showClaimsUploadModal: Function;
}

const UploadTypeModal = ({
  closeModal,
  showUploadModal,
  showClaimsUploadModal,
}: Props) => {
  const [redirectURL, setRedirectURL] = useState<string>('/claims');
  const [showClaimsDocView, setShowClaimsDocView] = useState<boolean>(false);
  const [showRedirectModal, setShowRedirectModal] = useState<boolean>(false);

  useFeatureFlagEffect(
    'Claims Hub Redirect',
    {
      onEnabled: () => {
        setRedirectURL(config.claims_portal_url);
      },
      onDisabled: () => {
        setRedirectURL('/claims');
      },
    },
    [],
  );

  useFeatureFlagEffect(
    'ClaimsDocumentUpload',
    {
      onEnabled: () => {
        setShowClaimsDocView(true);
      },
      onDisabled: () => {
        setShowClaimsDocView(false);
      },
    },
    [],
  );

  const handleClose = () => {
    simpleGTMDataLayer({
      event: `Documents-uploadDocumentTypeChoiceModalCancel-click`,
      event_action: 'Button Click',
      event_category: 'Documents',
      event_label: 'Upload Documents Type Choice Modal Cancel Button Click',
    });
    simpleGTMDataLayer({
      event: `Snowplow-Documents-uploadDocumentTypeChoiceModalCancel-click`,
      event_action: 'Snowplow Button Click',
      event_category: 'Snowplow Documents',
      event_label:
        'Snowplow Upload Documents Type Choice Modal Cancel Button Click',
    });
    closeModal();
  };

  return (
    <Backdrop
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      <UploadTypeModalWrap>
        {showRedirectModal ? (
          <ClaimsRedirectView
            redirect={() => {
              if (redirectURL === '/claims') {
                navigate(redirectURL);
                window.location.reload();
              } else {
                window.location.assign(redirectURL);
              }
            }}
            close={() => handleClose()}
          />
        ) : (
          <>
            <UploadTypeModalHeader>
              <ModalTextWrap>
                <ModalTitle>Upload Documents</ModalTitle>
                <ModalSubText>
                  Choose whether you want to upload policy or claim documents.
                </ModalSubText>
              </ModalTextWrap>
              <ModalCloseWrap onClick={() => handleClose()}>
                <SAIcon
                  icon={SAIcons.x}
                  size={SAIconSize.medium}
                  colorVariant="dark"
                />
              </ModalCloseWrap>
            </UploadTypeModalHeader>
            <ModalTypeWrap>
              <TypeSelectBlock
                uploadDocType="policy-document-upload"
                onClick={() => {
                  simpleGTMDataLayer({
                    event: `Documents-uploadDocumentTypeChoiceModalPolicy-click`,
                    event_action: 'Button Click',
                    event_category: 'Documents',
                    event_label:
                      'Upload Documents Type Choice Modal Policy Button Click',
                  });
                  simpleGTMDataLayer({
                    event: `Snowplow-Documents-uploadDocumentTypeChoiceModalPolicy-click`,
                    event_action: 'Snowplow Button Click',
                    event_category: 'Snowplow Documents',
                    event_label:
                      'Snowplow Upload Documents Type Choice Modal Policy Button Click',
                  });
                  showUploadModal();
                }}
              >
                <PolicyBlockIcon />
                <TypeBlockText>Policy</TypeBlockText>
              </TypeSelectBlock>
              <TypeSelectBlock
                uploadDocType="claims-document-upload"
                onClick={() => {
                  simpleGTMDataLayer({
                    event: `Documents-uploadDocumentTypeChoiceModalClaims-click`,
                    event_action: 'Button Click',
                    event_category: 'Documents',
                    event_label:
                      'Upload Documents Type Choice Modal Claims Button Click',
                  });
                  simpleGTMDataLayer({
                    event: `Snowplow-Documents-uploadDocumentTypeChoiceModalClaims-click`,
                    event_action: 'Snowplow Button Click',
                    event_category: 'Snowplow Documents',
                    event_label:
                      'Snowplow Upload Documents Type Choice Modal Claims Button Click',
                  });
                  if (showClaimsDocView) {
                    showClaimsUploadModal();
                  } else {
                    setShowRedirectModal(true);
                  }
                }}
              >
                <ClaimsBlockIcon />
                <TypeBlockText>Claims</TypeBlockText>
              </TypeSelectBlock>
            </ModalTypeWrap>
          </>
        )}
      </UploadTypeModalWrap>
    </Backdrop>
  );
};

export default UploadTypeModal;

import React from 'react';

import {
  UploadTypeModalHeader,
  ModalTextWrap,
  ModalTitle,
} from '../UploadTypeModal.styles';

import {
  ClaimsRedirectDescription,
  ClaimsRedirectFooter,
  CancelButton,
  ContinueButton,
} from './ClaimsRedirectViewStyles';

interface Props {
  redirect: Function;
  close: Function;
}

const ClaimsRedirectView = ({ redirect, close }: Props) => {
  return (
    <>
      <UploadTypeModalHeader>
        <ModalTextWrap>
          <ModalTitle>Redirecting to Claims</ModalTitle>
        </ModalTextWrap>
      </UploadTypeModalHeader>
      <ClaimsRedirectDescription>
        You will be redirected to claims page for uploading claims related
        documents.
      </ClaimsRedirectDescription>
      <ClaimsRedirectFooter>
        <ContinueButton onClick={() => redirect()}>CONTINUE</ContinueButton>
        <CancelButton onClick={() => close()}>CANCEL</CancelButton>
      </ClaimsRedirectFooter>
    </>
  );
};

export default ClaimsRedirectView;

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/return-await */
import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import axios, { AxiosResponse } from 'axios';
import AWSAppSyncClient from 'aws-appsync';
import { GET_CLAIMS_BY_ACCOUNT_account_items_claims_items } from '../documentsTable/queries/__generated__/GET_CLAIMS_BY_ACCOUNT';
import { formatDate } from '../../util/formatters';
import getConfig, { Env } from '../../aws_exports';
import ClaimsUploadModal from './ClaimsUploadModal';
import { ClaimObj } from './selectClaimView';
import { ClaimDocObj } from './uploadClaimDocs';
import { GET_SIGNED_S3_URL } from './graphql/__generated__/GET_SIGNED_S3_URL';

const config = getConfig(process.env.REACT_APP_ENV as Env);

const uploadClaimDoc = loader(
  './graphql/mutations/Upload_Claim_Document.graphql',
);

const getClaimsS3 = loader('./graphql/queries/Get_Signed_S3.graphql');

interface Props {
  awsAppSyncClient: AWSAppSyncClient<any>;
  closeModal: Function;
  claims: GET_CLAIMS_BY_ACCOUNT_account_items_claims_items[] | undefined;
}

export const organizeClaims = (
  claims: GET_CLAIMS_BY_ACCOUNT_account_items_claims_items[],
) => {
  return claims.map((claim) => {
    return {
      id: claim?.claimNumber ?? 'N/A',
      name: claim?.insuredName ?? 'N/A',
      location: claim?.lossLocation?.state ?? 'N/A',
      dateOfLoss: formatDate(claim?.lossDate) ?? 'N/A',
      policyNumber: claim?.policyNumber ?? 'N/A',
      incidents: claim?.incidents?.map((incident) => {
        if (incident?.incident?.vehicle) {
          return `${incident?.incident?.vehicle?.year} ${incident?.incident?.vehicle?.make} ${incident?.incident?.vehicle?.model}`;
        }
        if (incident?.incident?.property) {
          return `${incident?.incident?.property?.propertyName}`.split(',')[0];
        }
        if (incident?.incident?.medical) {
          return `${incident?.incident?.medical?.injured?.name}`;
        }
        if (incident?.incident?.injury) {
          return `${incident?.incident?.injury?.injured?.name}`;
        }
        return undefined;
      }),
    };
  });
};

const DataContainer = ({ awsAppSyncClient, closeModal, claims }: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<ClaimDocObj[]>([]);
  const [organizedClaims, setOrganizedClaims] = useState<ClaimObj[]>([]);
  const [selectedClaim, setSelectedClaim] = useState<ClaimObj | null>(null);
  const [relatedToOptions, setRelatedToOptions] = useState<string[]>(['--']);
  const documentTypeOptions: string[] = [
    '--',
    'Medical',
    'Damage',
    'Estimate',
    'Investigation',
  ];

  useEffect(() => {
    if (claims && claims.length) {
      setOrganizedClaims(organizeClaims(claims));
    }
  }, [claims]);

  useEffect(() => {
    if (selectedClaim) {
      const unique = selectedClaim?.incidents?.filter(
        (v, i, a) => a.indexOf(v) === i,
      );
      const filtered = unique?.filter((x) => typeof x !== undefined);

      setRelatedToOptions(
        filtered && filtered?.length
          ? relatedToOptions.concat(filtered as string[])
          : [''],
      );
    }
  }, [selectedClaim]);

  const uploadDocuments = async () => {
    if (uploadedFiles && uploadedFiles.length) {
      return new Promise((resolve, reject) => {
        uploadedFiles.forEach(async (doc) => {
          const uploadToEDS = async () => {
            const variables = {
              file_name: doc.file.name,
              user_display_name: sessionStorage?.getItem('userName'),
              claim_number: selectedClaim?.id,
              mime_type: doc.file.type,
              claim_doc_category: doc.documentType.value.toUpperCase(),
              claim_doc_sub_category: 'property',
              s3name:
                config.environment === 'prod'
                  ? 'sa-prod-eds-docs'
                  : 'sa-dev-eds-docs',
              policy_number: selectedClaim?.policyNumber,
            };

            await awsAppSyncClient
              .mutate({
                mutation: uploadClaimDoc,
                variables,
              })
              .then(() => {
                return resolve(true);
              })
              .catch((error) => {
                return reject(error);
              });
          };
          await awsAppSyncClient
            .query({
              query: getClaimsS3,
              variables: {
                account_number: sessionStorage?.getItem('accountNumber'),
                claim_number: selectedClaim?.id,
                file_name: doc.file.name,
              },
            })
            .then(async (result) => {
              const dataField: GET_SIGNED_S3_URL = ((result as unknown) as AxiosResponse)
                .data;

              const formData = new FormData();
              formData.append(
                'Key',
                dataField.account.items[0].signedUrlClaimsDocument.fields.Key,
              );
              formData.append(
                'bucket',
                dataField.account.items[0].signedUrlClaimsDocument.fields
                  .bucket,
              );
              formData.append(
                'X-Amz-Algorithm',
                dataField.account.items[0].signedUrlClaimsDocument.fields
                  .Algorithm,
              );
              formData.append(
                'X-Amz-Credential',
                dataField.account.items[0].signedUrlClaimsDocument.fields
                  .Credential,
              );
              formData.append(
                'X-Amz-Security-Token',
                dataField.account.items[0].signedUrlClaimsDocument.fields
                  .SecurityToken,
              );
              formData.append(
                'X-Amz-Date',
                dataField.account.items[0].signedUrlClaimsDocument.fields.Date,
              );
              formData.append(
                'Policy',
                dataField.account.items[0].signedUrlClaimsDocument.fields
                  .Policy,
              );
              formData.append(
                'X-Amz-Signature',
                dataField.account.items[0].signedUrlClaimsDocument.fields
                  .Signature,
              );
              formData.append('file', doc.file);

              const options = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              };

              return await axios
                .post(
                  dataField.account.items[0].signedUrlClaimsDocument.url,
                  formData,
                  options,
                )
                .then((res) => {
                  if (res.status === 204) {
                    uploadToEDS();
                  } else console.log('Error sending doc to S3 bucket');
                })
                .catch(() => {
                  console.log('Error sending doc to S3 bucket');
                });
            })
            .catch(() => {
              console.log('Error generating presigned S3 bucket');
            });
        });
      });
    }
  };

  return (
    <ClaimsUploadModal
      closeModal={closeModal}
      claims={organizedClaims}
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
      relatedToOptions={relatedToOptions}
      documentTypeOptions={documentTypeOptions}
      uploadDocuments={uploadDocuments}
      selectedClaim={selectedClaim}
      setSelectedClaim={setSelectedClaim}
    />
  );
};

export default DataContainer;

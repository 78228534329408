import styled from 'styled-components';
import customBase from '../../../util/global.theme';

import { ReactComponent as AlertSVG } from '../../../assets/images/Alert.svg';

const breakPoint = customBase.global.breakpoints.medium;

export const SelectClaimBody = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${breakPoint}px) {
    height: 100%;
  }
`;

export const ClaimsListWrap = styled.div`
  display: flex;
  @media screen and (min-width: ${breakPoint + 1}px) {
    width: 620px;
    padding: 12px 22px;
    flex-wrap: wrap;
  }
  @media screen and (max-width: ${breakPoint}px) {
    flex-direction: column;
  }
`;

const ClaimSelectBlockGutter = 16;

export const ClaimSelectBlock = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${breakPoint + 1}px) {
    width: calc(50% - ${ClaimSelectBlockGutter}px);
    padding: 12px 16px;
    margin: ${ClaimSelectBlockGutter / 2}px;
    background-color: #f4f6fa;
  }
  @media screen and (max-width: ${breakPoint}px) {
    padding: 12px 16px;
    border-bottom: 1px solid #d2dbe7;
  }
`;

export const ClaimBlockTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0173c6;
  margin: 0 0 5px;
`;

export const ClaimBlockTextRow = styled.div`
  display: flex;
  margin: 5px 0 0;
`;

const labelWidth = '88px';

export const TextRowLabel = styled.p`
  margin: 0;
  width: ${labelWidth};
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  color: #3b3b3b;
`;

export const TextRowText = styled.p`
  margin: 0;
  width: calc(100% - ${labelWidth});
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
`;

export const NoClaimsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 300px;
  @media screen and (max-width: ${breakPoint}px) {
    width: 100%;
    height: 100%;
  }
`;

export const NoClaimsIcon = styled(AlertSVG)`
  display: block;
  width: 72px;
  height: auto;
  margin-bottom: 30px;
`;

export const NoClaimsText = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #3b3b3b;
`;

export const CancelButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px;
  @media screen and (max-width: ${breakPoint}px) {
    display: none;
  }
`;

export const CancelButton = styled.p`
  cursor: pointer;
  padding: 0 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  border-radius: 4px;
  color: #3b3b3b;
  border-radius: 4px;
  :hover {
    background: #0173c6;
    color: #ffffff;
  }
`;

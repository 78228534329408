import styled from 'styled-components';
import customBase from '../../util/global.theme';

const breakPoint = customBase.global.breakpoints.medium;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background-color: #00000080;
  z-index: 9999;
`;

export const ClaimsUploadModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  @media screen and (min-width: ${breakPoint + 1}px) {
    border-radius: 3px;
  }
  @media screen and (max-width: ${breakPoint}px) {
    width: 100%;
    height: 100%;
  }
`;
